//Header


header{
    background:#fff;
    display: flex;
    flex:none;
    justify-content: space-between;
    width: 100%;
    padding: 15px 33px;
    align-items: center;
    @media only screen and (max-width: 1600px) {
        padding:13px 27px;
    }
    @media only screen and (max-width: 1600px) {
        padding:5px 27px;
    }
    .pageLogo{
        width:193px;
        height:auto;
        max-height:66px;
        cursor:pointer;
        @media only screen and (max-width: 1600px) {
            width:150px;
        }
        @media only screen and (max-width: 1400px) {
            width:124px;
        }
        img{
            width:100%;
            height:auto;
        }
    }
    /******User Profile ***/
    .user_profile_menu{
        outline: none;
        position:relative;
        btn{
            outline:none;
            box-shadow: none;
        }
        .btn.focus, .btn:focus {
            outline:none;
            box-shadow: none;
        }
        .last_seen_text{
            display:none;
            span{
                color:$red;
            }
        }
        .dropdown-toggle{
            padding-right:0;
        }
        .dropdown-toggle::after{
            display:none;
        }
        .dropdown{
            outline: none;

            .caret{
                margin: 8px 0 0 4px;
                @media only screen and (max-width: 1600px) {
                    margin: 6px 0 0 2px;
                }
                &::before{
                    content: "\e90b";
                    width: 18px;
                    height: 13px;
                    font-size: 6px;
                    display: block;
                    color: #000;
                    font-family: 'icomoon';
                    @media only screen and (max-width: 1600px) {
                        font-size:5px;
                    }
                }
            }
            &.show{
                .caret{
                    &::before{
                        content: "\e919";
                        font-family: 'icomoon';
                    }
                }
            }
        }
        .dropdown-menu{
            position: absolute;
            top: 16px !important;
            left: 0;
            z-index: 300;
            background: #fff;
            padding: 0;
            -webkit-box-shadow: 0px 2px 7px 0px #ccc;
            -moz-box-shadow:    0px 2px 7px 0px #ccc;
            box-shadow:         0px 2px 7px 0px #ccc;
            @media only screen and (max-width: 1600px) {
                top: 4px !important;
            }
            .dropdown-item{
                &.menu_condition{display:none;}
                padding: 12px 14px;
                display: block;
                border-bottom: 1px solid #eee;
                cursor: pointer;
                &:hover{
                     background:#EEE;
                }
            }
        }
        .user_profile_wrap{
            display:flex;
            cursor: pointer;
            position:relative;
            align-items: center;
            .profile_img{
                width: 45px;
                height: 45px;
                background: #203880;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                border-radius: 65%;
                margin-right: 10px;
                -webkit-align-items: center;
                align-items: center;
                -webkit-justify-content: center;
                justify-content: center;
                color: #fff;
                font-weight: 600;
                @media only screen and (max-width: 1600px) {
                    width: 30px;
                    height: 30px;
                }
            }
            .profile_wrap{
                display: flex;
                flex-direction: column;
                padding:0;
                @media only screen and (max-width: 600px) {
                    display:none;
                }
                .profile_name{
                    font-size:16px;
                    font-weight:normal;
                    color:#000;
                    text-align: left;
                    @media only screen and (max-width: 1600px) {
                        font-size:12px;
                    }
                    span{
                        display:inline-block;
                        vertical-align: top;
                    }
                }
                .profile_role{
                    color:#ABA7A7;
                    font-size:13px;
                    text-align: left;
                    margin-top: -3px;
                    @media only screen and (max-width: 1600px) {
                        font-size:10px;
                        margin-top:-3px;
                    }
                }
            }
        }

    }
}