@charset "UTF-8";

$icomoon-eot: url('./../../fonts/icons/icomoon.eot');
$icomoon-eot-opentype: url('./../../fonts/icons/icomoon.eot') format('embedded-opentype');
$icomoon-woff: url('./../../fonts/icons/icomoon.woff') format('woff');
$icomoon-ttf: url('./../../fonts/icons/icomoon.ttf') format('truetype');
$icomoon-svg: url('./../../fonts/icons/icomoon.svg') format('svg');

@font-face {
    font-family: 'icomoon';
    src:  $icomoon-eot;
    src:  $icomoon-eot-opentype,
          $icomoon-woff,
          $icomoon-ttf,
          $icomoon-svg;
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


//Icon Settings
//Main Icons
/*
$school-district:"\e927";
$schooldistrictIcon:"\e926";
$district-Icon:"\e925";
$districtIcon:"\e921";
$dashboardIcon:"\e917";
$studentIcon:"\e91a";
$userIcon:"\e92b";
$schoolIcon:"\e915";
$staffIcon:"\e93c";
$runIcon: "\e914";
$housesIcon:"\e90d";
$stopIcon:"\e916";
$movegroupIcon: "\e913";
$mileageIcon:"\e910";
$calendarIcon:"\e905";
$vehicleIcon: "\e904";
$toolsIcon:"\e918";

$moveIcon:"\e91e";

//Other Icons
$downIcon:"\e90b";
$upIcon:"\e919";
//
$editIcon:"\e91c";
$cloneIcon:"\e900";
$runassignmentIcon:"\e908";
$deleteIcon:"\e90a";
$closeIcon:"\e909";
$addIcon:"\e902";
$register:"\e903";
$siblingIcon:"\e912";
$viewlistIcon:"\e901";
$listpageicon:"\e90e";
$systemIcon:"\e91f";
$menuIcon:"\e920";
$mapIcon:"\e90f";
$editmenuIcon: "\e911";
$saveIcon:"\e907";
$cancelIcon: "\e906";
$addressIcon:"\e924";
$back_Icon:"\e923";
$bulb:"\e922";
$np:"\e936";
$ch:"\e935";
$schooldate:"\e938";
$contractor:"\e93a";
$oldstaff:"\e91b";
$right-arrow:"\e937";
$left-arrow:"\e923";
*/