.block_heading_container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 5px;
    .page_heading{
        display:flex;
        align-items: center;
        h2{
            display:inline-block;
            margin-bottom: 0;
        }
        .back_Icon{
            font-family: icomoon;
            margin-right: 10px;
            font-size: 11px;
            cursor: pointer;
            &::before{
                content:"\e923";
                font-size: 11px;
                font-family: 'icomoon';
            }
        }
    }
    .list_Icon{
        display:inline-block;
        margin-right:9px;
        &::before{
            content:"\e90e";
            font-family: 'icomoon';
            @media only screen and (max-width: 1600px) {
                font-size: 10px;
            }
        }
    }

    .listMenu{
        vertical-align: middle;
        display: inline-block;
        font-family: icomoon;
        margin-left: 13px;
        cursor: pointer;
        @media only screen and (max-width: 1600px) {
            margin-left:8px;
        }
    }

    .addmenu::before{
        content:"\e902";
        font-size: 18px;
        font-family: 'icomoon';
        @media only screen and (max-width: 1600px) {
            font-size: 14px;
        }
    }
    .editmenu::before{
        content:"\e91c";
        font-family: 'icomoon';
        font-size: 22px;
        @media only screen and (max-width: 1600px) {
            font-size: 18px;
        }
    }
    
    .ordermenu{
        margin-left:8px;
    }
    .ordermenu::before{
        content: "\e93f";
        font-size: 18px;
        font-family: 'icomoon';
        @media only screen and (max-width: 1600px) {
            font-size: 17px;
        }
    }
    .run-assignment::before{
        content:"\e908";
        font-family: 'icomoon';
        font-size: 18px;
        @media only screen and (max-width: 1600px) {
            font-size: 14px;
        }
    }
    .register_menu::before{
        content:"\e903";
        font-family: 'icomoon';
        font-size:24px;
        @media only screen and (max-width: 1600px) {
            font-size: 18px;
        }
    }
    .sibling_menu::before{
        content:"\e912";
        font-family: 'icomoon';
        font-size:24px;
        @media only screen and (max-width: 1600px) {
            font-size: 18px;
        }
    }
    .view_list_menu::before{
        content:"\e901";
        font-family:icomoon;
        font-size:25px;
        @media only screen and (max-width: 1600px) {
            font-size: 18px;
        }
    }
    .sibling_looksup_menu::before{
        content:"\e912";
        font-family: 'icomoon';
        font-size:25px;
    }
    .program_menu::before{
        content:"\e93d";
        font-family: 'icomoon';
        font-size:25px;
        @media only screen and (max-width: 1600px) {
            font-size: 18px;
        }
    }
    .schooldate_menu::before{
        content:"\e938";
        font-family: 'icomoon';
        font-size:25px;
        @media only screen and (max-width: 1600px) {
            font-size: 18px;
        }
    }
    .contractor_menu::before{
        content:"\e93a";
        font-family: 'icomoon';
        font-size: 20px;
        top: -2px;
        position: relative;
        color:#e99803;
        @media only screen and (max-width: 1600px) {
            font-size: 16px;
            top: -2px;
        }
    }
}

.createIcon{
    color:#000;
    &::before{
        content:"+";
        font-size: 15px;
        font-family: 'Arial';
        height: 21px;
        @media only screen and (max-width: 1600px) {
            font-size: 14px;
            height: 20px;
        }
    }
}

.back_Icon{
    font-family: icomoon;
    margin-right: 10px;
    font-size: 11px;
    cursor: pointer;
    &::before{
        content:"\e923";
        font-size: 11px;
        font-family: 'icomoon';
    }
}

.inline-text-link{
    display: flex;
    align-items: center;
    color: #D7265C;
    cursor: pointer;
    .inline-link-button{
        margin-left: 6px;
    }
}

.mapIcon{
    &::before{
        content: "\e90f";
        font-size:12px;
        font-family:icomoon;
    }
    
    &.inline-icon-gap{
        margin-right:5px;
    }
}

.linkSpan{
    text-decoration: underline;
    cursor: pointer;
}