.center-block{
    margin: 20px auto;
    width: 25%;
    min-width: 300px;
    display: block;
    overflow: hidden;
    -webkit-box-shadow:0px 10px 17px -14px #999;
    -moz-box-shadow:0px 10px 17px -14px #999;
    box-shadow: 0px 10px 17px -14px #999;
  
}