//Accordion

//Accordion

.accordion-panel{    
    width:100%;    
    .panel-item{
        width:100%;
        margin-bottom: 3px;
        .panel-heading{
            background: #F5F5F5;
            width: 100%;
            padding: 16px 18px;
            cursor:pointer;
            position:relative;
            @media screen and (max-width: 1600px) {
                padding: 12px 16px;
            }
            .panel-icon{
                font-family:icomoon;
                margin-right: 8px;
                &.address-icon{
                    &::before{
                        content:"\e924";
                        font-family: 'icomoon';
                    }
                }
            }
            .panel-title{
                font-weight:600;
            }
            &::before{
                content:"\e90b";
                position: absolute;
                right: 10px;
                top: 0;
                font-family: 'icomoon';
                color: #000;
                font-size: 6px;
                bottom: 0;
                margin: auto 0;
                height: 8px;
                @media only screen and (max-width: 1600px){
                    font-size: 5px;
                    height: 6px;
                }
            }
        }
        .panel-content{
            width: 100%;
            padding: 30px 20px;
            display:none;
        }
        &.expand{
            .panel-heading{
                &::before{
                    content:"\e919";
                    font-family: 'icomoon';
                }
            }
            .panel-content{
                display:block;
            }
        }
    }
}