

.mapLeft-col{
    width: 78%;
    margin-right: 10px;
    flex-direction: column;
    display:flex;
    @media screen and (max-width: 1201px) {
        width:100%;
        margin-right:0;
    }
}

.mapRight-col{
    flex:1;
    padding: 22px;
    min-width: 306px;
    @media screen and (max-width: 1201px) {
       display:none;
    }
   
}

.mapView{
    margin-bottom: 10px;
    padding: 10px;
    height: 344px;
    @media screen and (max-width: 1201px) {
        height:268px;
    }
}

.mapTable{
    padding:24px 33px;
    flex:1;
}

.mapContainer {
    display: flex;
    background: #000;
    min-height: 200px;
    overflow: hidden;
    position: relative;
    &.fullheight{
        height:100%;
    }
}


.map-display{
    .view-data-row{
        justify-content: space-between;
        border-bottom:1px solid #ccc;
        display:flex;
        padding:10px 0;
        .col-section{
            label{
                width:100%;
            }
            .data-value{
                width:100%;
                font-weight:600;
                max-width:130px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            &.rightside{
                text-align:right;
            }
        }
    }
}

.mapSearch {
    margin-top:10px;
    display:flex;
    justify-content: space-evenly;
}

.mapSearchbtn{
align-items: flex-end;
display: flex;
margin-left: 12px;
}

.mapSearchwrap{
    label{
        margin-bottom:0;
    }
    input{
        color:#FFF;
        &::placeholder {
            color:#ccc;
        }
    }
}

.fullwidthbtn{
    width:100%;
}