html,body,h1,h2,h3,h4,a,p,label,input,select,ul,li{
    font-family:'Roboto', sans-serif;
}

html, body{
    font-size:$lg_size;
    line-height:normal;
    min-height:100vh;
    @media only screen and (max-width: 1600px) {
        font-size:$sm_size;
    }
}

body{
    background:#ECECEC;
}

a{
    color:$red;
}

h1{
    font-size:$heading;
    font-weight: 600;
    line-height: normal;
    margin-top: 7px;
    @media only screen and (max-width: 1600px) {
        font-size:$sm_heading;
    }
}

h2{
    font-size:$subheading;
    font-weight:bold;
    text-transform: uppercase;
    @media only screen and (max-width: 1600px) {
        font-size:$sm_subheading;
    }
}

h3{
    font-size:$blocktitle;
    font-weight:bold;
    text-transform: uppercase;
    @media only screen and (max-width: 1600px) {
        font-size:$sm_subh3;
    }
}


h4{
    font-size:$blocksectiontitle;
    font-weight:bold;
    @media only screen and (max-width: 1600px) {
        font-size:$smblocksectiontitle;
    }
}

h5{
    font-size:$blocksubsectiontitle;
    font-weight:bold;
    color: #000;
    @media only screen and (max-width: 1600px) {
        font-size:$smblocksubsectiontitle;
    }
}

sub{
    font-size:58%;
    bottom: -0.01em;
}

strong{
    font-weight:bold;
}

div{
    outline:none;
    box-shadow: none;
}

::-webkit-input-placeholder { /* Edge */
    color:#BFBFBF;
    &.error{
        color:$error;
    }
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:#BFBFBF;
    &.error{
        color:$error;
    }
  }

  ::placeholder {
    color:#BFBFBF;
    &.error{
        color:$error;
    }
  }

#root {
    box-sizing: border-box;
    min-height:100vh;
}

.wrapper{
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    min-height:100vh;


}


.version_text{
    color:#000;
    font-weight:bold;
    font-size:13px;
    span{
        color:$red;
    }
    @media only screen and (max-width: 1600px) {
        font-size:11px;
    }
}


.notMobile{
    display:flex;
    @media only screen and (max-width: 1199px) {
        display:none !important;
    }
}

.for_mobile_tab{
    display:none;
    @media only screen and (max-width: 1199px) {
        display:flex !important;
    }
}

.mainContent{
    display: flex;
    width: 100%;
    flex-grow: 1;
    position:relative;
}

.setpageMargin{
    display: flex;
    margin: 0 20px;
    width:100%;
    @media screen and (max-width: 1600px) {
        margin: 0 13px;
    }
    @media screen and (max-width:900px) {
        overflow: hidden;
    }
    &.inner_page{
        padding-left:40px;
        min-height:250px;
        @media screen and (max-width: 1600px) {
            padding-left:33px;
        }
    }

}

.block{
    background:$block;
    position:relative;
}

.innerContainer{
    flex: 0 0 100%;
    max-width: 100%;
    padding:24px 33px;
    margin-bottom: 20px;
    @media screen and (max-width: 1600px) {
        padding:19px 25px;
    }
    @media screen and (max-width: 900px) {
        padding:25px;
    }
    @media screen and (max-width: 600px) {
        padding:12px;
    }
}

.align_center{
    text-align:center;
}

.header_nav{
    position:relative;
    display:flex;
    align-items: center;
    .notification-section-panel{
        margin-right:10px;
        .badge{
            height: 23px;
            padding: 0 7px !important;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            color: #fff;
            right: -3px;
            border: none !important;
            border-radius: 6px !important;
        }
    }
}




