//Tile
.tile_row{
    display: flex;
    flex-wrap: wrap;
    height:376px;
    justify-content: space-between;
  
    @media screen and (max-width: 1600px) {          
        height: 264px;
    }
    .tile{        
        color:#fff;
        width:24%;       
        margin-bottom:1.5%;
        position:relative;
        cursor:pointer;
        height: 31%;        
        .tile_icons{
            position: absolute;
            width: 100%;
            height:49%;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;     
            color:#fff;
            font-family:icomoon;
            display: flex;
            align-items: flex-end;
            justify-content: center; 
        }
        .tile_name{
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: 59%;
            text-align: center;
            font-size: 19px;
            letter-spacing: 0.2px;
            color: #fff;
            @media (max-width: 1600px) {           
                font-size: 13px;
            }
        }
        //Set Color
        &.runTile{
            background:$run;
            .tile_icons{
                &::before{
                    content:"\e914";
                    font-family: 'icomoon';
                    font-size: 35px;
                    @media screen and (max-width: 1600px) {
                        font-size: 25px;
                    }
                }
            }
        }
        &.schoolTile{
            background:$school;
            .tile_icons{
                &::before{
                    content:"\e915";
                    font-family: 'icomoon';
                    font-size: 32px;
                    @media screen and (max-width: 1600px) {
                        font-size: 22px;
                    }
                }
            }
        }
        &.staffTile{
            background:$staff;
            .tile_icons{
                &::before{
                    content:"\e93c";
                    font-family: 'icomoon';
                    font-size: 35px;
                    @media screen and (max-width: 1600px) {
                        font-size: 25px;
                    }
                }
            }
        }
        &.stopTile{
            background:$stop;
            .tile_icons{
                &::before{
                    content:"\e916";
                    font-family: 'icomoon';
                    font-size: 35px;
                    @media screen and (max-width: 1600px) {
                        font-size: 25px;
                    }
                }
            }
        }
        &.studentTile{
            background:$student;
            .tile_icons{
                &::before{
                    content:"\e91a";
                    font-family: 'icomoon';
                    font-size: 32px;
                    @media screen and (max-width: 1600px) {
                        font-size: 22px;
                    }
                }
            }
        }
        &.toolsTile{
            background:$tools;
            .tile_icons{
                &::before{
                    content:"\e918";
                    font-family: 'icomoon';
                    font-size: 32px;
                    @media screen and (max-width: 1600px) {
                        font-size: 22px;
                    }
                }
            }
        }
        &.mileageTile{
            background:$mileage;
            .tile_icons{
                &::before{
                    content:"\e910";
                    font-family: 'icomoon';
                    font-size: 32px;
                    @media screen and (max-width: 1600px) {
                        font-size: 22px;
                    }
                }
            }
        }
        &.reportTile{
            background:$report;
            .tile_icons{
                &::before{
                    content:"\e90c";
                    font-family: 'icomoon';
                    font-size: 32px;
                    @media screen and (max-width: 1600px) {
                        font-size: 22px;
                    }
                }
            }
        }
        &.vehicleTile{
            background:$vehicle;
            .tile_icons{
                &::before{
                    content:"\e904";
                    font-family: 'icomoon';
                    font-size: 32px;
                    @media screen and (max-width: 1600px) {
                        font-size: 22px;
                    }
                }
            }
        }
        &.housesTile{
            background:$houses;
            .tile_icons{
                &::before{
                    content:"\e90d";
                    font-family: 'icomoon';
                    font-size: 32px;
                    @media screen and (max-width: 1600px) {
                        font-size: 22px;
                    }
                }
            }
        }
        &.calendarTile{
            background:$calendar;
            .tile_icons{
                &::before{
                    content:"\e905";
                    font-family: 'icomoon';
                    font-size: 32px;
                    @media screen and (max-width: 1600px) {
                        font-size: 22px;
                    }
                }
            }
        }
        &.movegroupTile{
            background:$movegroup;
            .tile_icons{
                &::before{
                    content: "\e91e";
                    font-family: 'icomoon';
                    font-size: 32px;
                    @media screen and (max-width: 1600px) {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}