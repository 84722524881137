.modal{
    z-index: 5000;
    .modal-content{
        max-height:98%;
        position:absolute;
        .modal-header{
            padding: 18px 19px;
            .modal-title {
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase
            }
        }
        .modal-body{
            padding: 18px 19px;
            overflow: hidden;
            overflow-y: auto;
            max-height: calc(100vh - (100px + 80px));
            .selectAutocompleteInput__menu{
                position:fixed;
                top:auto !important;
                width:300px;
                z-index: 5010;
            }
            .table-responsive{
                display:flex;
                //height:calc(100% );
                margin-bottom:20px;
                min-height:10px;
                height: calc(100vh - (100px + 274px));
                flex:1;
                .innerTableContainer{
                    position:relative;
                    flex:1;
                    .table-scroll{
                        position:absolute;
                        top:0;
                        left:0;
                        width:100%;
                        height:100%;
                    }
                }
            }
        }
        .modal-footer{
            padding: 18px 12px;
        }
    }
}

.full-size{
    .modal-body{
        display:flex;
        > .row{
            flex:1;
        }
    }
}

.lg-size{
    .modal-dialog{
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .modal-content{
            width: 98%;
            flex: 1 1;
            display: flex;
            position: absolute;
            height: 98%;
            min-height: 98%;
            z-index: 400;
            overflow: auto;
        }
    }
}

.lg-auto-size{
    .modal-dialog{
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .modal-content{
            width: 98%;
            flex: 1 1;
            display: flex;
            position: absolute;
            height: 80%;
            min-height: 80%;
            z-index: 400;
            overflow: auto;
        }
    }
}


.md-size{
    .modal-dialog{
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .modal-content{
            width:80%;
            max-width: 1060px;
            .modal-body{
                overflow: auto;
            }
        }
    }
}



.sm-size{
    .modal-dialog{
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .modal-content{
            width: 36%;
            min-width: 646px;
            @media only screen and (max-width: 800px){
                width: 100%;
                min-width: 300px;
            }
            .modal-body{
                overflow-x: hidden;
                overflow: auto;

            }
        }
    }
}



.xs-size{
    .modal-dialog{
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .modal-content{
            width: 98%;
            max-width: 400px;
            .modal-body{
                padding: 18px 19px;
                overflow: visible;

            }

        }
    }
}

.win-size{
    .modal-dialog{
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .modal-content{
            width: 98%;
            max-width: 568px;
            height: 70%;
            position: absolute;
            @media only screen and (max-width:1600px) {
                height: 82%;
            }
            .modal-body{
                padding: 18px 19px;
                // overflow: visible;
            }


        }
    }
    &.listheight{
        .modal-content .modal-body{
            display:flex;
            overflow: hidden;
        }

    }
}

.no-modal-overflow{
    .modal-content .modal-body{
        display:flex;
        overflow: hidden;
        @media only screen and (max-width: 992px) {
            overflow:auto;
        }
    }
}


.modal-inner-popup-right{
    position: absolute;
    z-index: 200;
    background: #fff;
    top: 0;
    right: -80%;
    width: 50%;
    padding: 17px 38px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
    &.open{
        right:0;
    }
}

.overflowmodal .modal-content .modal-body{
    overflow: visible;
}

.calendarPage.sm-size, .studentPage.md {
    .modal-body{
        overflow:hidden !important;
        .table-responsive{
            min-height:10px;
        }
    }
}

.studentPage.md.modal .modal-content .modal-body {
    .table-responsive{
        height: calc(100vh - (100px + 330px));
    }
}

.exportRun{
    .modal-body{
        .selectAutocompleteInput__menu{
            position:absolute !important;
            top:0 !important;
            width:300px;
        }
    }
}