//Run Assignment - Run details
.row-highlight{
    background: #F6F6F6;
    width: 101.5%;
    padding: 10px;
    margin-bottom: 20px;

}
 
.runbg{
    //background: url("../../images/run-details-bg.png") no-repeat scroll 0 -41px #F6F6F6;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(120,126,228,1) 0%, rgba(190,122,200,1) 100%);
    display:flex;
    flex-direction: column;
}
.grey-bg{
    background:#F6F6F6;
}
.top-dt-section{
    padding-bottom:54px;
}
.bottom-dt-section{
    position:relative;
    padding-top:0;
}
.overflow-box{
    position: relative;
    top: -51px;
    margin-bottom: -55px;
}
.run-heading{
    color:#fff;
    font-weight:bold;
    font-size:22px;
    h3{
        color:#fff;
        text-align: center;
    }
}

.round-details-panel{
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    width: 304px;
    height: 100px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    margin: 24px auto;
    border-radius: 4px;
    @media screen and (max-width:1600px) {
        width: auto;
    }
    .panel-round-details{
        font-size:22px;
        margin-top:10px;
        font-weight:600;

    }
    .panel-label{
        font-size:16px;
    }
}

.time-details-panel{
    justify-content: space-between;
    display:flex;
    color:#fff;
}

.box-panel-details{
    .box-label{
        font-size:14px;
    }
    .box-details{
        font-size:18px;
        font-weight:bold;
    }
    &.center{
        text-align: center;
    }
    &.right{
        text-align: right;
    }
}

.sp-panel{
    background:#fff;
    width:100%;
    padding:15px;
    justify-content: space-between;
    margin:20px 0;
    display:flex;
    box-shadow: 0px 0px 6px 0px rgb(223, 223, 223);
}