.title-center{
    display: flex;
    padding:10px 0;
    width:100%;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    color:#000;
}
.module-text{
    color:#729e29;
}

.report-list-wrap{
    min-height:250px;
    display: flex;
    flex-direction: column;
    padding: 0 14%;
    @media screen and (min-width: 1601px) {
        padding: 0 20%;
    }
    @media screen and (max-width: 600px) {
        padding: 0;
    }
    .list-button-ul{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin:0;
        padding:0;
        flex-wrap: wrap;
        li{
            width: 47%;
            min-width: 335px;
            max-width: 338px;
            font-weight: 600;
            align-items: center;
            list-style: none;
            background: #eeeeee;
            border: 1px solid #ded9d9;
            padding: 8px;
            align-items: center;
            margin: 5px;
            color: #000;
            text-align: left;
            cursor: pointer;
            font-weight: 600;
            &:last-child {
                text-align: center;
                @media screen and (max-width: 1100px) {
                    text-align: left;
                }
            }
            @media screen and (max-width: 730px) {
                width:100%

            }
            @media screen and (min-width: 1601px) {
                min-width: 400px;
                max-width: 400px;
            }
            @media screen and (max-width: 600px) {
                min-width: 100%;
                max-width: 100%;
            }
        }
    }
}

//Report Wrapper
.report-wrap{
    padding:0 15px;
    display:flex;
    flex-direction: column;
    width: 100%;
    .report-container{
        display:flex;
        flex-grow: 1;
        .innerContainer{
            display:flex;
            flex-grow: 1;
            flex-direction: column;
        }
    }
}

.report-top-row{
    display:flex;
    width:100%;
    margin-top:20px;
}
.print-btn{
    cursor: pointer;
    white-space: nowrap;
}
.report-data-container{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    @media only screen and (max-width: 992px) {
        margin-top:10px;
        flex-direction: column;
    }
    .report-left-row{
        display:flex;
        width:20%;
        margin:10px 10px 10px 0;
        flex-wrap: wrap;
        flex-direction: column;
        @media only screen and (max-width: 992px) {
            width:100%;
        }
    }
    .report-center-row{
        display:flex;
        flex:1;
        background:#ccc;
        margin:10px 0;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: top;
        justify-content: top;
        min-height:700px;
        position:relative;
        iframe{
            border:none;
        }
    }
    .report-right-row{
        display:flex;
        flex:1;
        margin:10px 0 10px 10px;
        flex-wrap: wrap;
        flex-direction: column;
    }
}

.printtypeInput{
    width:35px !important;
}

.col-130-auto{
    padding:0 15px;
    width:130px;
    @media screen and (min-width: 1601px) {
        width:145px;
    }
    @media screen and (max-width: 992px) {
        width:50%;
    }
    @media screen and (max-width: 600px) {
        width:100%;
    }

}

.col-200-auto{
    padding:0 15px;
    width:200px;
    @media screen and (min-width: 1601px) {
        width:280px
    }
    @media screen and (max-width: 992px) {
        width:50%;
    }
    @media screen and (max-width: 600px) {
        width:100%;
    }
}

.two-line-col-condition{
    padding: 0 15px;
    width: 257px;
    @media screen and (min-width: 1601px) {
        width:312px
    }
}


.create-report-btn{
    width: auto;
    white-space: nowrap;
    margin:30px 0;
    @media screen and (max-width: 992px) {
        margin-left:0 !important;
    }
    @media screen and (max-width: 600px) {
        width:100%;
    }
}

.create-report-btn-excel{
    width: auto;
    white-space: nowrap;
    margin:30px 0;
    @media screen and (max-width: 992px) {
        margin-left:5px !important;
    }
    @media screen and (max-width: 600px) {
        width:100%;
        margin-left:0 !important;
        margin-top:10px !important;
    }
}

.printIcon{
    &::before{
        font-family:"icomoon";
        content:"\e93e";
        color:#000;
        margin-right:5px;

    }
}

.right-controls{
    width: 100%;
    background: #999;
    color: #000;
    padding: 10px;
    display: flex;
    max-height: 51px;
    .print-btn{
        background: #729E29;
        padding: 7px 18px;
        border-radius: 3px;
        margin-left: auto;
        color:#fff;
        height: 31px;
        .printIcon{
            &::before{
                font-family:"icomoon";
                content:"\e93e";
                color:#fff;
                margin-right:5px;
            }
        }
    }
    .btn{
        background:#fff;
        margin:0 0 0 5px;
        border-radius:3px;
        border:none;
    }
}

.circularloader{
    position:absolute;
    margin:auto;
    left:0;
    right:0;
    top:0;
    bottom:0;
    width:120px;
    height:120px;
    img{
        width:100%;
        height:auto;
    }
}