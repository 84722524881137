.table{
    width:100%;
    font-family:roboto;
    font-size:$table;
    @media only screen and (max-width: 1600px) {
        font-size:$sm_table;
    }
    thead{
        //  tr:nth-child(1) th{
        //     position: sticky;
        //     top: 0;
        //     z-index: 10;
        //  }

        tr{
            th{
                text-transform: uppercase;
                padding:7px 5px;
                border:none;
                border-bottom:1px solid #D5D5D5;
                color:#666;
                font-size:$tablehead;
                font-weight:normal;
                cursor:pointer;
                background-color:#fff;
                @media only screen and (max-width: 1600px) {
                    font-size:$sm_tablehead;
                }
                .caret{
                    &::before{
                     content:"\e90b";
                     color: #fff;
                     font-size: 5px;
                     font-family: 'icomoon';
                     margin: 0 0 0 7px;
                     @media only screen and (max-width: 1600px) {
                        font-size:4px;
                     }
                    }
                }
                &.descending{
                    .caret{
                        &::before{
                         content:"\e90b";
                         font-family: 'icomoon';
                         color: #9C9A9A;
                        }
                    }
                }
                &.ascending{
                    .caret{
                        &::before{
                         content:"\e919";
                         font-family: 'icomoon';
                         color: #9C9A9A;
                        }
                    }
                }
                &.tablebtn{
                    width:56px;
                    text-align:left;
                    pointer-events: none;
                    .caret{
                        display:none;
                    }
                }
            }
        }
    }
    tbody{
        tr{
            td{
                border:none;
                border-bottom:1px solid #D5D5D5;
                color:$sitefont;
                padding:7px 5px;
                vertical-align: middle;
                cursor:pointer;
                .editbtn{
                    &::before{
                        content:"\e91c";
                        color: #333;
                        font-size:16px;
                        font-family: 'icomoon';
                    }

                }
                .clonebtn{
                    &::before{
                        content:"\e900";
                        color: #333;
                        font-size:16px;
                        font-family: 'icomoon';
                    }

                }
                .deletebtn{
                    &::before{
                        content:"\e90a";
                        color: #333;
                        font-size: 16px;
                        font-family: 'icomoon';
                    }

                }

            }
            &.selected{
                td{
                    background:#efefef;
                }
            }
            &.noAssign{
                td{
                    &.highlight{
                        color:#EC1818;
                    }
                }
            }
        }
    }
}




.nodata{
    text-align: center;
    margin: 42px 0;
    .bulb{
        font-family:icomoon;
        opacity: 0.7;
        &::before{
            content:"\e922";
            font-family: 'icomoon';
            font-size: 56px;
            color:#ccc;
            @media only screen and (min-width: 1700px) {
                font-size: 68px;
            }
        }
    }
    .noDataText{
        color: #9E9E9E;
        font-size: 13px;
        margin: 20px 0;
        @media only screen and (min-width: 1700px) {
            font-size: 18px;
            margin: 25px 0;
        }
        div{
            margin: 14px 0;
            font-weight: 500;
        }
    }
}


.leftTableFixed, .rightTableFixed{
    table tr th{
        white-space: nowrap;
    }
    .nodata {
        margin: 9px 0;
        .bulb::before{
            font-size: 48px;
        }
        .noDataText{
            font-size: 13px;
        }
    }
}


table.form-table{
    width:100%;
    tr{
        td{
            padding:1px 7px;
            &.trip-Width{
                min-width:60px;
                max-width: 100px;
            }
            /*
            &.trip-Width{
                width:180px;
            }
            &.select-Width{
                width:124px;
            }
            &.extra-trip-Width{
                width:114px;
            }
            &.extra-trip-indicator-Width{
                width:170px;
            }
            */
        }
    }
}

table.inputDataTable{
    tr td{
        padding: 10px 0;
        vertical-align: middle;
    }
    input, select{
        border:none;
        max-width:180px;
    }
}

.numbercode{
    background: #EEE;
    border-radius: 60px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.number-cell{
    width:80px;
}


//RESPONSIVE
/*
@media only screen and (max-width: 900px) {
    table, thead, tbody, th, td, tr {
		display: block;
    }
    thead{
        display:none;
    }
    .table tbody tr{border-bottom:1px solid #D5D5D5;}
    .table tbody tr td{
        padding-top: 27px;
        position: relative;
        font-weight: 600;
        font-size: 14px;
        min-height: 52px;
        border-bottom:none;
    }
    .table tbody tr td::before {
        content: attr(data-label);
        position: absolute;
        top: 7px;
        font-weight: 400;
        font-size: 12px;
        color: #9C9A9A;
        text-transform: uppercase;
    }
}

*/

table{
    .selectAutocompleteContainer .selectAutocompleteInput__control{
        border:none;
    }
}


table.sectionTable{
    min-width:212px;
    height:80px;
    @media only screen and (max-width: 1217px) {
        min-width:0;
    }
    h3{
        margin:0;
    }
    tr{
        td{
            white-space: nowrap;
            vertical-align: middle;
            height: 30px;
        }
    }
}

.stickyHeader{
    th{
        position: sticky ;
        left:0;
        top: 0;
        z-index: 2;
    }

}
.maxHt300{
    @media only screen and (min-height: 800px) and (max-height: 1367px) and (min-width: 700px)  {
        max-height: calc(100vh - 410px);
    }
    @media only screen and (min-height: 500px) and (max-height: 799px) and (min-width: 700px) {
        max-height: calc(100vh - 410px);
    }
    // ipad pro
    @media only screen and (min-height: 500px) and (max-height: 1080px) and (min-width: 1800px) {
        max-height: calc(100vh - 500px);
    }
    //pagination
    @media only screen and (min-height: 500px) and (max-height: 1080px) and (min-width: 1800px) {
        max-height: calc(100vh - 500px);
    }

}
.ht100{
    height: 100%;
}

.noWrap{
    td{
        white-space: nowrap;
    }
}
