.headerWidget{
    font-size: 13px;
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 1600px) {
        font-size: 10px;
    }
    .widget_title{
        color:$red;
        text-transform: uppercase;
        @media only screen and (max-width: 1600px) {
            font-size: 10px;
        }
    }
    .widget_dropdown{
        display: block;
        text-align: right;
        select{
            border: none;
            font-size: 14px;
            width: 89px;
            height: auto;
            margin: 1px 0 0;
            padding: 0px 0 0px;
            background-size: 12px;
            @media only screen and (max-width: 1600px) {
                font-size: 10px;
                width: 62px;
                background-size: 9px;
            }
        }
        .selectAutocompleteInput__single-value{
            font-size: 15px;
            @media only screen and (max-width: 1600px) {
                font-size: 11px;
            }
        }
        .selectAutocompleteContainer {
            &.assign_widget{
                .selectAutocompleteInput__control{
                    border:none !important;
                    background:transparent !important;
                    min-height: 20px;
                    @media only screen and (max-width: 1600px){
                        min-height: 13px;
                    }
                }
                .selectAutocompleteInput__placeholder, .selectAutocompleteInput__single-value {
                    top: 55%;
                    transform: translateY(-44%);
                    width: 85px;
                    max-width:100%;
                    margin-right: 3px;
                }
                .selectAutocompleteInput__indicator.selectAutocompleteInput__dropdown-indicator{
                    height:20px;
                    background-position: center 9px;
                    @media only screen and (max-width: 1600px){
                        height:13px;
                        background-position: center 6px;
                    }
                }
            }
        }
    }
    .splitDiv{
        margin-left:30px;
        .greenhighlight{
            color:#000;
        }
    }
}