//FONT SIZE
$lg_size:16px;
$lg_label_head:17px;
$heading:22px;
$subheading:16px;
$blocktitle:16px;
$blocksectiontitle:16px;
$blocksubsectiontitle:16px;
$inputsize:17px;
$tablehead:14px;
$table:16px;
$lg_button:15px;

$sm_size:14px; //sm
$sm_label_head:14px; //sm
$sm_heading:15px;
$sm_subheading:14px; //sm
$sm_blocktitle:14px; //sm
$sm_blocksectiontitle:14px; //sm
$sm_inputsize:14px; //sm
$sm_subh3:14px; //sm
$smblocksectiontitle:14px; //sm
$smblocksubsectiontitle:14px; //sm
$sm_tablehead:13px; //sm
$sm_table:14px; //sm
$sm_button:13px; //sm

//Calendar
$status-msg:11px;
@media only screen and (max-width: 1600px) {
    $status-msg:10px;
}

//FONT COLORS
$sitefont:#000;
$inputcolor:#000;
$red:#F50B0B;
$error:#FF0000;

// Button

$login_button:#CB1D23;
$login_button_text:#FFFFFF;
$login_button_border : #A50202;
$btn_radius:30px;

//Other

$ContainerGrey:#ECECEC;
$block:#fff;

//Module Color

$run:#203880;
$school:#0B6CC1;
$user:#D90000;
$staff:#8000C9;
$stop:#CB1D23;
$student:#E20062;
$vehicle:#F6B435;
$tools:#107C10;
$calendar:#0080FF;
$mileage:#00538B;
$report:#729E29;
$movegroup:#80397B;
$houses:#DC3C00;

