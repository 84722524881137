//Tab

.tab-container{
    display:flex;
    width:100%;
    border:1px solid hsl(0, 0%, 89%);
    position:relative;
    @media only screen and (max-width: 600px) {
        padding-top:40px;
    }
    .tabMobile{
        display:none;
        @media only screen and (max-width: 600px) {
            position:absolute;
            top:0px;
            display:block;
            background: url("../../images/dropdown_icon.png") no-repeat scroll 98% center transparent;
            font-size:11px;
            padding:10px;
            color:$inputcolor;
            border: none;
            border-bottom: 1px solid#D5D5D5;
            width: 100%;
        }
    }
    .tab-menu{
        width:132px;
        background:#F5F5F5;
        flex-direction: column;
        position:relative;
        min-height: 421px;
        @media only screen and (max-width: 600px) {
            position: absolute;
            top: 40px;
            z-index: 10;
            width: 100%;
            background:#FFF;
            min-height: auto;
            height:200px;
            overflow: auto;
            border-bottom:1px solid #ccc;
            display:none;
        }
        .tab-menu-li{
            width: 100%;
            font-size: $lg_size;
            padding: 15px;
            color: #333;
            display:flex;
            align-items: center;
            cursor:pointer;
            @media only screen and (max-width: 1600px) {
                font-size:$sm_size;
            }
            @media only screen and (max-width: 600px) {
                padding: 10px;
            }
            &.active{
                background:#fff;
                color:#D7265C;
            }
            .warning-icon{
                background: url("../../images/warning-icon.png") no-repeat scroll 0 0 transparent !important;
                color: #fff;
                padding:0;
                font-weight: bold;
                border-radius: 15px;
                margin-left: 7px;
                height: 13px;
                width:13px;
                display: none;
            }
            &.warning{
                .warning-icon{
                    display:inline-block;
                }
            }
        }
    }
    .tab-result{
        padding: 18px 33px;
        width: 100%;
        display: flex;
        overflow:hidden;
        position:relative;
        @media only screen and (max-width:992px){
            padding:18px 15px;
        }
        .full-width-panel{
            width:100%;
        }
    }
    .result-container{
        padding: 29px 0;
        &.set-result-margin{
            padding:29px 5px;
        }
    }
}