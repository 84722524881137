.cardwrap{
    flex:0 0 100%;
    display:flex;
    justify-content: space-between;
    border-radius: 3px;
    .card-item{
        width: 32%;
        border: 1px solid #eee;
        padding: 32px;
        .card-title{
            font-weight: 600;
            margin-bottom: 26px;
            .address-icon{
                margin-right: 8px;
                &::before{
                    content:"\e924";
                    font-family: 'icomoon';
                }
            }
        }
    }
}