.profile-wrap{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    padding: 0 9px;
    width: 100%;
    flex: 0 0 100%;
    .user-profile{
        background: url("../../images/Profile.jpg") no-repeat;
        width: 130px;
        height: 132px;
        background-size: 100%;
        margin-right: 30px;
    }
    .profile-data{
        .profile-name{
            font-size: 21px;
            color: #D90000;
            font-weight: 600;
            @media screen and (max-width: 1600px) {
                font-size:19px;
            }
        }
        .profile-usertype{
            color:#666;
        }
        .profile-other-details{
            margin-top: 16px;
            .plainmodeSpan{
                display:block;
                margin-bottom:2px;
                .data-value{
                    font-weight:600;
                    color:#000;
                }
                label{
                    margin-bottom:0;
                    margin-right:5px;
                }
            }
        }
    }
}

.email-highlight{
   .data-value{
    color:#3399FF;
   } 
}