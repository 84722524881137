.fa {
    font-family:icomoon;
    &.fa-chevron-left{
        &::before{
         content:"\e923";
         font-family:icomoon;
        }
    }
    &.fa-chevron-right{
        &::before{
            content:"\e937";
            font-family: 'icomoon';
        }
    }
}

.fc-col-header {
    tr{
        th{
            text-align: right;
            border: none;
            border-bottom: 1px solid #dee2e6;
            padding: 2px 10px;
            text-transform: uppercase;
            font-size: 10px;
        }
    }
}
.fc-day-other{
    .fc-bg-event{
        &.isactive{

            opacity:.2 !important;        }

    }
}

.fc-daygrid-day {
    &.fc-day {
        .fc-daygrid-day-number{
            padding: 7px;
            border-radius: 100%;
            font-weight: bold;
            width: 28px;
            text-align: center;
            margin: 4px;
            font-size: 12px;
        }
        &.fc-day-today {
            background:#fff !important;
            .fc-daygrid-day-number{
                background:#0080FF;
                color:#fff;
            }
        }

    }
    .status-block{
        top: 38px;
        position: absolute;
        width: 100%;
        height: auto;
    }
    .fc-bg-event{
        opacity:1 !important;
    }
    .calendar-daily-status{
        display:flex;
        justify-content: space-between;
        .active-status{
            //background: #0CB754;
            width:50%;
            background:rgb(11, 128, 67);
            color: #fff;
            font-size:11px;
            margin-bottom: 3px;
            padding:2px 5px;
            display:none;
            font-weight: 600;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            @media only screen and (max-width: 1600px) {
                padding:2px 9px;
                font-size:9px;
            }
        }
        .ed-status{
            width:50%;
            background:#FBBC04;
            color: #fff;
            font-size:$status-msg;
            padding: 2px 5px;
            font-weight: 600;
            display:none;
            margin-bottom: 3px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            @media only screen and (max-width: 1600px) {
                padding: 2px 7px;
                font-size:9px;
            }
        }
    }
    .calendarCommentWrap{
        margin-top: 4px;
        .calendar-comments{
            color: #3c4043;
            font-size:11px;
            margin-bottom: 3px;
            padding: 2px 16px;
            font-weight: 600;
            position: relative;
            padding-left: 16px;
            white-space: nowrap;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            display:none;
            @media only screen and (max-width: 1600px) {
                font-size:9px;
                padding: 1px 16px;
            }
            &::before{
                background: #039be5;
                border-radius: 50px;
                content: "";
                width: 7px;
                height: 7px;
                position: absolute;
                top: 0;
                left: 5px;
                bottom: 0;
                margin: auto 0;
            }
        }
    }

    .isactive{
        .active-status{
            display:block;
        }
    }
    .earlydismiss{
        .ed-status{
            display:block;
        }
    }

    .specialNotesComment{
        .sp-comments{
            display:block;
        }
    }

    .extendedComment{
        .ext-comments{
            display:block;
        }
    }


}

.main-calendar{
    position:relative;
    .fc .fc-scrollgrid-liquid{
        height: 569px;
        @media only screen and (max-width: 1600px)  {
            height: 513px;
        }
    }
    table.fc-scrollgrid-sync-table{
        min-height: 544px;
        max-height: 544px;
        @media only screen and (max-width: 1600px)  {
            min-height: 489px;
            max-height: 489px;
        }
    }
    .fc .fc-toolbar.fc-header-toolbar{
        margin-bottom:5px;
    }
    .activedays{
        position:absolute;
        max-width:243px;
        padding-right:30px;
        top:6px;
        right:40px;
    }
    .selectAutocompleteContainer{
        position:absolute;
        top:1px;
        max-width:243px;
        @media only screen and (max-width: 1600px)  {
            max-width:200px;
        }
        .selectAutocompleteInput__control{
            border:none;
        }
        .selectAutocompleteInput__single-value{
            font-size:15px;
            font-weight: 700;
            text-transform: uppercase;
            padding-right:30px;
            &::before{
                content: "\e90b";
                width: 18px;
                height: 13px;
                font-size: 5px;
                display: block;
                color: #000;
                font-family: 'icomoon';
                position:absolute;
                top:6px;
                right:2px;
                @media only screen and (max-width: 1600px)  {
                    font-size: 5px;
                    top:6px;
                }
            }
        }
        .selectAutocompleteInput__indicator{
            display:none;
        }
    }
    .fc-toolbar-chunk{
        .fc-PrevMon-button, .fc-NextMon-button{
            background: #EEE !important;
            color: #000 !important;
            border: none;
            font-size: 18px;
            padding: 0 9px;
            margin: 0;
        }
    }
}

.fc-view-harness{
    height:580px !important;
    @media only screen and (max-width: 1600px)  {
        height:529px !important;
    }
}

.reportContainer{
    width: 100%;
    .center{
        text-align: center;
    }
    .content-break{
        margin: 15px 0;
    }
    table.reportTable{
        width:100%;
        border:1px solid darkgray;
        border-collapse: collapse;
    th, td{
      padding: 1px; padding:0.2rem;
      border: 1px solid;
      &.number{text-align:right;}
      &.heading{text-align:left;}
      &.date, &.icon{text-align:center;}
    }
    thead th{
      background-color: #f7f7f7;
      border:1px solid darkgray;
    }
    tbody{
      th,td {
        border: none
      }
    }
    tfoot{
      background-color: #eee;
      color: #000;
      font-weight:bold;
    }
  }}