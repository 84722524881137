//SCSS for Login Page
.login-container {
        width: 100%;
        display: flex;
        min-height:100vh;
        //background: url("../../images/corner_img.png") no-repeat scroll right 0 transparent;
        flex-wrap: wrap;
        @media only screen and (max-width: 1600px) {
            background-position: 106% -43px;
        }
        .left-region {
            min-height:100vh;
            background: url("../../images/left_bg.jpg") no-repeat scroll right 0 transparent;
            background-size: cover;
            position:relative;
            width:50%;
            overflow:hidden;
            @media only screen and (max-width: 1199px) {
                width:100%;
                order:2;
                flex:0 0 100%;
                height:636px;
            }
            @media only screen and (max-width: 600px) {
                height:378px;
            }
            .banner_text_one{
                color:#fff;
                background:#1A1917;
                font-size: 40px;
                margin:46px 0 0;
                display: inline-block;
                padding: 12px 32px;
                letter-spacing: 1px;
                @media only screen and (max-width: 1600px) {
                    font-size:22px;
                    margin-right:9%;
                }
                @media only screen and (max-width: 600px) {
                    font-size:16px;
                    margin-top:12px;
                    padding:8px 18px;
                }
            }
            .banner_text_two{
                color: #fff;
                background: #A82F34;
                font-size: 30px;
                margin: 19px 0;
                display: inline-block;
                padding: 12px 32px;
                letter-spacing: 1px;
                @media only screen and (max-width: 1600px) {
                    font-size:16px;
                    margin: 13px 0;
                    margin-right:15%;
                }
                @media only screen and (max-width: 600px) {
                    font-size:12px;
                    margin:4px 0;
                    padding:8px 18px;
                }
            }
            .login_copyright{
                position:absolute;
                bottom:0;
                width:100%;padding:10px 0;
                color:#fff;
                text-align: center;
                justify-content: center;
            }
        }
        .right-region {
            //background: url("../../images/bg_light.jpg") no-repeat scroll 0 bottom transparent;single_right_bg.jpg
            background: url("../../images/single_right_bg.jpg") no-repeat scroll right 0 transparent;
            background-size: cover;
            min-height:100vh;
            width: 50%;
            display: flex;
            align-items: center;
            position:relative;
            flex-direction: column;
            overflow:hidden;
            @media only screen and (max-width: 1199px) {
                width: 100%;
                order: 1;
                flex: 0 0 100%;
                height: auto;
                padding-bottom: 36px;

            }
            .login_form {
                width: 39%;
                margin-top: 186px;
                flex-direction: column;
                align-items: center;
                min-width: 283px;
                @media only screen and (max-width: 1600px) {
                    margin-top:48px;
                    width: 44%;
                    min-width: 279px;
                }
                @media only screen and (max-width: 1260px) {
                    width: 48%;
                }
                @media only screen and (max-width: 1199px) {
                    margin-top:48px;
                }
                .logo {
                    width: 100%;
                    margin: 10px 0 77px;
                    img {
                        width: 100%;
                        height: auto;
                        vertical-align: top;
                    }
                }
                .login_form_wrap{
                    width: 95%;
                    margin: auto;
                }
                .login_remember{
                    border-left: 1px solid #000;
                    padding-left: 10px;
                    margin-left: 9px;
                    display:inline-block;
                    font-size:14px;
                    @media only screen and (max-width: 1600px) {
                        font-size:12px;
                    }

                }
                .login_field{
                    label{
                        font-size:15px;
                        margin-bottom: 0;
                        @media only screen and (max-width: 1600px) {
                            font-size:12px;
                        }
                    }
                    input[type="text"],
                    input[type="password"]{
                        font-size:15px;
                        @media only screen and (max-width: 1600px) {
                            font-size:12px;
                        }
                    }
                }
                .forgot_passoword_link{
                    text-decoration: underline;
                    color:$red;
                    cursor: pointer;
                    font-size:14px;
                    @media only screen and (max-width: 1600px) {
                        font-size:12px;
                    }
                }
                .form_submit {
                    margin: 30px 0;
                    .btn {
                        background: $login_button;
                        color: $login_button_text;
                        border: 1px solid $login_button_border;
                        border-radius: $btn_radius;
                        width: 100%;
                        font-size: 20px;
                        font-weight: normal;
                        @media only screen and (max-width: 1600px) {
                            font-size:17px;
                        }
                    }
                }
                .mobile_ams{
                    .ams_logo{
                        width: 90%;
                        max-width: 275px;
                        margin-top: 0px;
                        img{
                            max-width:100%;
                        }
                    }
                }
            }
            .login-footer{
                display: flex;
                justify-content: space-between;
                margin-top:auto;
                width:100%;
                background:#fff;
                padding:11px 13px;
                align-items: center;
                @media only screen and (max-width: 1600px) {
                    padding: 5px 9px;
                }
                .ams_logo{
                    width:258px;
                    @media only screen and (max-width: 1600px) {
                        width: 213px;
                    }
                    img{
                        max-width:100%;
                    }
                }

            }
        }
        .mobile_footer{
            display:none;
            .ams_logo{
                width: 79%;
                margin:10px auto 0;
                max-width: 266px;
                text-align:center;
                img{
                    max-width:100%;
                }
            }
            @media only screen and (max-width: 1199px){
                .version_text {
                    font-size: 12px;
                    margin: 5px 0;
                    text-align:center;
                }
            }
            @media only screen and (max-width: 1199px) {
                display: flex;
                flex: 0 0 100%;
                flex-direction: column;
                order: 3;
                padding: 5px 4%;
                .mobile_copyright{
                    font-size: 11px;
                    margin-bottom: 20px;
                    text-align:center;
                }
            }
        }
    }
