@media only screen and (max-width: 1119px) {
    .wrapper{
        .cus-row{
            flex-wrap: wrap;
            .col-cus-5, .col-cus-7{
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0;
                margin-bottom: 13px;
            }
            .toprow{
                padding: 0;
            }
            
            .tile_row {
                height: auto;
                min-height: 1px;
                max-height: 100%;
                .tile{
                    min-height: 81px;
                }
            }
        }
        .device-none{
            display:none;
            flex-grow: 0;
        }
    }
}
@media only screen and (max-width: 1119px) and (min-width: 1000px){
    .tile_row {
        height: auto;
        min-height: 1px;
        max-height: 100%;
        .tile{
            height: 119px;
            margin-bottom: 1%;
            width: 16%;
        }
    }
}

@media only screen and (max-width: 999px) and (min-width: 700px){
    .wrapper .cus-row {
        .tile_row {
            height: auto;
            min-height: 1px;
            max-height: 100%;
            .tile{
                height: auto;
                min-height: 100px;
                width: 16%;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    header {
        padding: 5px 14px;
    }
    .setpageMargin{
        padding:0 !important;
        &.inner_page{
            padding:0 !important;
            margin:0 !important;
        }
    }
    .left_menu_block {
        left: -50px;
    }
    .title {
        width:231px;
    }
    .search_row {
        .search-filters{
            flex: 0 0 100%;
            max-width: 100%;
            width:100%;
            height:auto;
            .search-fields{
                flex: 0 0 100%;
                max-width: 100%;
                width:100%;
                .search-col{
                    width: 100%;
                    margin: 10px 0;
                }
            }
        }
        .search-action{
            flex: 0 0 100%;
            max-width: 100%;
            width:100%;
            height:auto;
            margin-bottom:30px;
            input, button {
                width: 49%;
                margin: 0 1%;
            }
        }
    }
}