.react-date-picker__calendar-button{
    display: none !important;
}

.react-date-picker__clear-button{
    position:absolute;
    right:0;
}

.flex-float-component{
    display:flex;
    flex-wrap: wrap;
}

.attendance-row{
    width: 23.8%;
    padding: 20px 29px 11px;
    margin: 0.6%;
    border: 1px solid #E4E4E4;
    border-radius: 5px;
}

.section-border{
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 17px;
}

.add-filter{
    padding: 0 10px 20px;
    width: 100%;
    text-align: right;
    display: block;
    overflow: hidden;
}

.block_container {
    display: block;
    width: 100%;


}

.set-row-margin-space{
    margin-left:-5px;
    margin-right:-5px;
}

.set-row-3{
    padding: 32px 20px;
}


.align-center{
    align-items: center;
}

.disable-container{
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
    opacity: 0.3;
    display:none;
}

.innerHead{
    padding:0 7px;
}
.priorityHead{
    background: none;
    border: none;
    width: 20px;
    font-weight: bold;
}

.highlight{
    color:$red;
}

.user-view-form, .set-form-align {
    padding:0 6px;
}

.note{
    color:#FF0000;
    font-size:12px;
}

.go-back{
    color: #D90000;
    text-decoration: underline;
    text-align: center;
    font-size: 12px;
    display: block;
    width: 100%;
    margin: 25px 0;
    cursor: pointer;
}
.center-align{
    justify-content: center;
}
.seperate-div{
   border-right:1px solid #EEE;
   @media only screen and (max-width: 992px) {
    border:none
   }
}

.left-padding{
    padding-right:36px;
    @media only screen and (max-width: 992px) {
        padding:14px;
    }
}

.input-set-padding{
    padding-left:21px;
}

.center-padding{
    padding:0 36px;
    @media only screen and (max-width: 992px) {
        padding:0 14px;
    }
}

.right-padding{
    padding-left:36px !important;
    @media only screen and (max-width: 992px) {
        padding:14px !important;
    }
}

.icon-disabled{
    opacity:0.3;
}

.disabled-button{
    opacity:0.5;
    pointer-events: none;
}

.disabled-view{
    visibility: hidden;
}

.selectedBuilding{
    display: block;
    font-size: 17px;
    font-weight: 600;
    margin-top: 4px;
}

.displayBlock{
    width:100%;
    display:block;
}

.label-condition-alignment{
    margin-top:11px;
}

.no-result-td{
    border:none !important;
}

.alignCenter-calculation{
    display:flex;
    align-items: center;
}

.modal-list-block{
    height: 230px;
    overflow: auto;
    width:100%;
}

.section-seperator{
    margin-bottom:17px;
}

.section-right-padding{
    padding-left:30px;
}

.section-left-padding{
    padding-right:30px;
}

.lessMargin{
    .inputmargin{
        margin:0;
    }
}

.borderTop{
    border-top:1px solid #D5D5D5;
}

.borderBottom{
    border-bottom:1px solid #EEE;
}
.minwidth-btn{
    min-width:150px;
}
.default-flex{
    display:flex;
    width:100%;
}

.alignright{
    margin-left:auto;
}

.justifyDiv{
    justify-content: space-between;
    align-items: center;
}

.leftmarginbtn{
    margin-left:5px;
}

.data-col-card{
    font-weight:600;
}

.breadcumb-arrow{
    &::before{
        font-family:icomoon;
        content:"\e937";
        font-size: 11px;
        color: #666;
        margin: 0 7px;
    }
}

.two-side-div{
    display:flex;
    justify-content: space-between;
    padding:10px 0;
    @media screen and (max-width: 1217px) {
        flex-direction: column;
        div{
            width:100%;
            flex:0 0 100%;
            margin-bottom:20px;
            button{
                width:48%;
                @media screen and (max-width: 600px) {
                    width:100%;
                    margin-left:0;
                    margin-right:0;
                    margin-bottom:10px;
                }
            }
        }
    }

}

.highlighText{
    color:$red;
    font-weight:600;
}

.showRecords{
    font-weight:600;
}

.form-padding-set{
    padding:0 5px;
}

.extraTripIndicator{
    background: url("../../images/tick.png") no-repeat;
    width: 22px;
    height: 20px;
    margin:0 9px;
    display: inline-block;
    @media only screen and (max-width:700px) {
        margin:0 2px;
    }
}

.copyto{
    background: url("../../images/copynext.png") no-repeat;
    width: 19px;
    height: 19px;
    margin: 15px 0px 15px 15px;
    display: inline-block;
    cursor: pointer;
    .fieldcopy{
        margin: 15px 0px 15px 7%;
    }
    @media only screen and (max-width:700px) {
        margin:0 2px;
    }
}
.withIcon{
    .form-row{
        width: 85%;
    }

}

.mileagePage{
    .error{
        position: relative!important;
        white-space: normal!important;
        bottom: unset!important;
    }
}

.right-elements{
    @media only screen and (max-width:700px) {
        width:100%;
    }
}

.mobile-unwrap{
    @media only screen and (max-width:700px) {
        flex-wrap: wrap;
    }
}

.mobilfull{
    @media only screen and (max-width:700px) {
        width:100%;
    margin:10px 0;
    }

}

.daysPanel{
    font-weight:600;
    .count{
        color:$red;

    }
}

.contactBlock{
    border: 1px solid #D5D5D5;
    width: 23%;
    margin: 10px;
    padding: 19px 5px 9px;
    border-radius: 3px;
    position: relative;
    .cornerBox{
        position:absolute;
        right:0;
        width:43px;
        top:0;
        height:42px;
        .counttext{
            width: 22px;
            text-align: center;
            font-size: 13px;
            display: block;
            color: #fff;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 29px;
            font-weight: 600;
            z-index: 200;
            right: 0;
        }
        .corner-tri{
            position: absolute;
            display: block;
            width: 42px;
            height: 42px;
            border-style: solid;
            border-width: 0 41px 42px 0; /* adjust for size of triangle */
            border-color: transparent #0B6CC1 transparent transparent; /* adjust for color of triangle */
            top: 0;
            right: 0;
            z-index: 100;
            color: white;
            text-shadow: 0 0 25px 9px #fff;

        }
    }

}

.nospace{
    padding:0;
    label{
        margin:0;
    }
}

.side-accordion{
    width:100%;
    margin-bottom:4px;
    .side-accordion-header{
        background:#dcdcdc;
        padding:7px 10px;
        position: relative;
        cursor: pointer;
        .caret{
            position: absolute;
            right: 5px;
            top: 12px;
            &::before {
                content: "\e90b";
                width: 18px;
                height: 13px;
                font-size: 6px;
                display: block;
                color: #000;
                font-family: 'icomoon';
            }
        }
        &.open .caret{
            &::before {
                content: "\e919";
                font-family: 'icomoon';
            }
        }
    }
    .side-accordion-content{
        padding:10px 10px 0;
        display:none;
        &.open{
            display:block;
        }
    }
}

.wrap-row-label{
    padding:5px 0;
    display: block;
    margin: 0;
    label{
        margin:0;
    }
}
.withSelect{
    display: flex;
    .form-row{
        padding-bottom: 0;
    }
}

.sq-btn{
    color: #fff;
    padding: 13px 10px;
    margin-bottom: 10px;
}

.reportbtn{
    border: 1px solid #769346;
    background: #729E29;
}

.sideDesciption{
    height:166px;
}

.filter-two-way{
    justify-content: space-between;
    display:flex;
    flex: 1;
    @media only screen and (max-width: 992px) {
        flex-direction: column;
        width:100%;
    }

}

.form-flex{
    display:flex;
    flex-direction: column;
    flex: 1;;
}
.custom-col-md-5 {
    flex: 0 0 50%;
    max-width: 50%;
    @media screen and (max-width: 992px) {
        flex: 0 0 100%;
        max-width:100%;
    }
}
.custom-col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    @media screen and (max-width: 992px) {
        flex: 0 0 100%;
        max-width:100%;
    }
}

.timepickerDiv{
    position:relative;
    .react-timekeeper {
        position:absolute;
        top:50px;
        left:0;
        margin:0;
        z-index:20;
    }
}

.center-checkbox{
    padding-top: 20px;
}

.full-width{
    width:100%;
}

.selectedhighlight{
    padding:0 -3px;
    .selected-hightlight-data{
        font-size:16px;
        display:block;
        font-weight:600;
    }
    .selected-hightlight-label{
        display:block;
        font-size:11px;
    }
}

.text-icons{
    align-items: center;
    display:flex;
    .set-icon{
        margin-left: 13px;
        display: flex;
        align-items: center;
        span{
            font-family: roboto;
            margin-bottom: 4px;
            margin-left: 2px;
            font-size: 12px;
            font-weight: bold;
        }
    }
}

.para-text{
    margin-bottom:10px;
}

.inline-checkbox-row{
    margin: 0 10px;
    display:flex;
    flex-wrap: nowrap;
    .col-cus-field-2{
        margin-right:20px;
        .form-row{
            margin:0;
        }
    }
}

.color-blue{
    label{
        color:#0B6CC1;
    }
}
.color-red{
    label{
        color:#D90000;
    }
}
.color-green{
    label{
        color:rgb(3, 104, 3);
    }
}
.flexBox-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}
.list-container{
    border: 1px solid #ccc;
    box-shadow: inset 0 0 9px #ccc;
    padding: 10px 12px;
    overflow-y: inherit;
    display: flex;
    flex: 1;
    flex-direction: column;
    .list-item {
        display:block;
        padding:0;
        margin:4px 0;
    }
    input[type="text"]{
        border-bottom:none;
    }
}

.mb-4-mob{
    @media only screen and (max-width:600px) {
        margin-bottom:10px;
    }
}

.disabled-status{
    opacity:0.2;
}

.page-min-height{
    min-height:600px;
    @media only screen and (max-width: 700px) {
        min-height:1300px;
    }
}

.mapBlockview{
    width:100%;
    height:400px;
}

.conditionMapView{
    height:400px;
}

.strong-font{
    font-weight:700;
}

.fixed50{
    height:250px;
    overflow-y: auto;
    margin-bottom:30px;
}

.fixed300{
    height:300px;
    overflow-y: auto;
    margin-bottom:30px;
}

.align-select-input-pop{
    padding: 0 5px 19px;
    .error{
        left:5px !important;
    }
}