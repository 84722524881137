.normalUser{
    .tile_row{
        height:250px;
        @media screen and (max-width: 1600px){
            height:186px;
        }
        .tile {
            height: 47%;
            display:none;
            @media screen and (max-width: 1600px){
                height: 46%;
            }
            &.normal-user{
                display:block;
            }
            &.large{
                width:49%;
            }
            &.extra-large{
                width:49.5%;
            }

        }
    }
}