input,select,a,button{
    outline:none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
 -webkit-appearance:none;
  appearance:none;
}

#root .block{
    input:-internal-autofill-selected{
        background-color:#fff !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #FFF inset !important;
    }
}


input[type="text"],
input[type="number"],
input[type="file"],
input[type="password"]{
    font-size:$inputsize;
    font-weight: bold;
    color:$inputcolor;
    outline:none;
    background: none;
    border: none;
    border-bottom: 1px solid #D5D5D5;
    width: 100%;
    padding:3px 0;
    height:28px;
    text-overflow: ellipsis;
    @media only screen and (max-width: 1600px) {
        font-size:$sm_inputsize;
    }
    &.error{
        color:$error;
        border-bottom:1px solid $error;
        font-size:14px;
        text-transform: capitalize;
        @media only screen and (max-width: 1600px){
            font-size: 10px;
        }
    }
    &::placeholder {
        color:#ccc;
        font-weight: normal;
        &.error{
            color:$error;
            text-transform: capitalize;
            @media only screen and (max-width: 1600px){
                font-size: 10px;
            }
        }
      }
}

::-webkit-file-upload-button {
    background:#fff;
    color: #000;
    border:1px solid #000;
    padding: 3px 10px;
    margin-bottom:3px;
    border-radius:5px;
    font-size:14px;
  }
  input[type="file"]{
      font-size:13px !important;
      border:none !important;
  }

.react-date-picker__inputGroup__input{
    border: none !important;
    padding: 0 !important;
    height: auto !important;
    margin: 0 !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
 -webkit-appearance:none;
  appearance:none;

}

input[type="number"]{
    -moz-appearance:textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #FAFAFA inset !important;
}
input:disabled::-webkit-input-placeholder { /* WebKit browsers */
    color:    #fff;
}
input:disabled:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    #fff;
}
textarea:disabled::-webkit-input-placeholder {
    /* WebKit browsers */
     color: transparent;
}
 textarea:disabled:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
     color: transparent;
}

input[type="checkbox"]{
    color:#000;
}

select{
    background: url("../../images/dropdown_icon.png") no-repeat scroll right center transparent;
    font-size:$inputsize;
    color:$inputcolor;
    border: none;
    border-bottom: 1px solid#D5D5D5;
    width: 100%;
    padding:3px 0;
    outline:none;
    cursor:pointer;
    height:28px;
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
    &::-ms-expand {
        display: none;
    }
    @media only screen and (max-width: 1600px) {
        font-size:$sm_inputsize;
    }
    option{
        padding:10px;
        line-height:20px;
    }
}

textarea{
    border: 1px solid#D5D5D5;
    width:100%;
    height:80px;
    resize: none;
    margin:9px 0;
    padding:10px;
    font-size:$inputsize;
    font-weight: bold;
    &.tab_note{
        height:200px;
        width:60%;
    }
    &.blocktextarea{
        height:80px;
    }
    @media only screen and (max-width: 1600px) {
        font-size:$sm_inputsize;
    }
    &::placeholder {
        color:#ccc;
        font-weight: normal;
        &.error{
            color:$error;
            text-transform: capitalize;
            @media only screen and (max-width: 1600px){
                font-size: 10px;
            }
        }
      }
}

.form_set{
    padding-bottom: 18px;
    margin-bottom: 6px;
    position: relative;
    span.error{
        color: $error;
        font-size: 12px;
        position: absolute;
        bottom: 4px;
        letter-spacing: 0.4px;
        left: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-transform: capitalize;
        overflow: hidden;
        width: 100%;
        @media only screen and (max-width: 1600px) {
            font-size:9px;
        }
    }
}

.form-row{
    position:relative;
    padding-bottom: 19px;
    label{
        width:100%;
        margin-bottom:0;
        input{
            margin-right:5px;
        }
    }
    span.error{
        color: $error;
        font-size: 12px;
        position: absolute;
        bottom:4px;
        letter-spacing: 0.4px;
        text-transform: capitalize;
        left: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        @media only screen and (max-width: 1600px) {
            font-size:9px;
        }
    }
    &.col-cus-element-4{
        display:flex;
        padding-bottom: 15px;
        .col-cus-field-2{
            width:auto;
            margin-right:30px;
            padding-left: 0;
            @media only screen and (max-width: 1360px) {
                margin-right: 16px;
            }
            label,span{
                margin: 0 0 9px;
                display: flex;
                align-items: center;
            }
        }
    }
}

.checkbox-input{
    label,span{
        margin: 0 0 9px;
        display: flex;
        align-items: center;
    }
}

.form-head, .form-align {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

label{
    &.with_input{
        display:inline-block;
        margin-right: 7px;
        vertical-align: top;
        margin-top: 1px;
        input{
            vertical-align: top;
            margin-right: 6px;
            margin-top: 1px;
        }
    }
}

.inline-check{
    label{
        display:inline-block;
        margin-right: 7px;
        vertical-align: top;
        margin-top: 1px;
        input{
            vertical-align: top;
            margin-right: 6px;
            margin-top: 1px;
        }
    }
}

.setbtn{
    border: 1px solid #707070;
    background: #fff;
    color: #000;
    padding: 4px 26px;
    font-size: $lg_button;
    border-radius: 25px;
    outline:none;
    max-height: 30px;
    &.blockBtn{
        padding: 2px 26px;
        @media only screen and (max-width: 1600px) {
            font-size:$sm_button;
            padding: 2px 19px;
        }
    }
    &.sq-btn{
        border-radius: 2px;
    }
    &.blackbtn{
        border:1px solid #000;
        background:#000;
        color:#fff;
    }
    @media only screen and (max-width: 1600px) {
        font-size:$sm_button;
        padding: 4px 19px;
    }

}
.nowWrap-button .setbtn{
    @media only screen and (max-width: 600px) {
        margin-top:10px;
    }
}
.cancel-close{
    background: #CB1D23;
    color: #FFFFFF;
    border: 1px solid #A50202;
    &:hover{
      color: #FFFFFF;
    }
}
.blk-btn{
    background: #333;
    color: #FFFFFF;
    border: 1px solid #000;
    &:hover{
      color: #FFFFFF;
    }
}
.drop-btn{
    .btn{
        border: 1px solid #707070;
        background: #fff;
        color: #000;
        padding:3px 16px;
        font-size: $lg_button;
        border-radius: 25px;
        outline:none;
        max-height: 30px;
        &::after{
            border:none;
            content:"\e90b";
            font-size: 6px;
            margin-left: 8px;
            font-family: icomoon;
            @media only screen and (max-width: 1600px) {
                font-size:5px;
            }
        }
        @media only screen and (max-width: 1600px) {
            font-size:$sm_button;
            padding: 4px 19px;
        }
    }
    &.show .btn{
        &::after {
            content: "\e919";
            font-family: 'icomoon';
        }
    }

}

.drop-menu{
    padding:0;
    border:none;
    box-shadow: 0px 2px 7px 0px #ccc;
    .dropdown-item {
        padding:9px 12px;
        display: block;
        border-bottom: 1px solid #eee;
        cursor: pointer;
    }
}


.datepicker{
    background: url("../../images/datepicker.png") no-repeat scroll 98% 4px transparent !important;
    padding:3px 0 4px;
    padding-right:20px !important;
    cursor:pointer;
    border-bottom: thin solid #D5D5D5;
    width:100%;
    .react-date-picker__wrapper{
        border:0;
        color:#BFBFBF;
        margin-top: -1px;
        position:relative;
        .react-date-picker__clear-button {
            position: absolute;
            right: 3px;top:0;
            padding:0;
            top: 1px;
            opacity:0.4;
            @media only screen and (max-width: 1600px) {
                top:-2px;
            }
            &:hover{
                opacity:1;
                display:block;
            }

        }
    }
    @media only screen and (max-width: 1600px) {
        padding: 6px 0 6px;
    }
}

.react-calendar{
    &.calendarClassName{
        border-radius: 0;
        border:none;
        -webkit-box-shadow: 0px 11px 18px -8px #999;
        -moz-box-shadow:    0px 11px 18px -8px #999;
        box-shadow:         0px 11px 18px -8px #999;
    }

}
.react-date-picker__calendar {
    width: 100% !important;
    font-size: 12px;
    min-width:180px;
    max-width: 240px !important;
    right:0 !important;
    left:auto !important;
    @media screen and (max-width: 1600px) and (min-width: 1000px) {
        max-width: 180px !important;
        font-size: 10px;
    }
}

.react-calendar__navigation button{
    min-width:2px !important;
}
.react-calendar__month-view__days{
    button{
        padding:7px 4px;
        @media screen and (max-width: 1600px) and (min-width: 1000px) {
            padding:5px 4px;
        }
    }
}
.react-calendar__navigation {
    height: 29px !important;
    background: #eee;
}
//Search
.single_search_input{
    border: 1px solid #C7C7C7;
    width: 100%;
    border-radius: 11px;
    padding: 2px 9px;
    font-size: 10px;
}
//Autocomplete

.selectAutocompleteContainer {
    width:100%;
    .selectAutocompleteInput__value-container{
        padding:0;
        margin:0;
        div:nth-child(2) {
            margin:0;
            padding:0;
            height: 27px;
        }
    }
    .selectAutocompleteInput__control{
        min-height:28px;
        border: none;
        background-color:#fff;
        border-bottom: 1px solid #D5D5D5;
        border-radius: 0;
        box-shadow:none;
        outline:none;
        cursor: pointer;
    }
    .selectAutocompleteInput__indicator{
        padding:0;
        color: #000;
        &.selectAutocompleteInput__dropdown-indicator  {
            height: 25px;
            width: 18px;
            background: url("../../images/dropdown_icon.png") no-repeat scroll center 11px transparent;
            @media only screen and (max-width: 1600px){
                background-size:10px;
            }
            svg{
                display:none;
            }
        }
        &.selectAutocompleteInput__clear-indicator{
            &::before{
                content:"\e909";
                font-family: 'icomoon';
                font-size: 9px;
                top: 2px;
                right: 10px;
                position: relative;
            }
            svg{
                display:none;
            }
        }
    }
    .selectAutocompleteInput__indicator-separator {
        display:none;
    }

    .selectAutocompleteInput__placeholder,
    .selectAutocompleteInput__single-value{
        top:49%;
        transform: translateY(-51%);
        margin-left: 0;
        font-size:$inputsize;
        font-weight:700;
        @media only screen and (max-width: 1600px) {
            font-size:$sm_inputsize;
        }
    }
    .selectAutocompleteInput__placeholder{
        font-weight:normal;
        color:#ccc;
    }


    .selectAutocompleteInput__menu {
        z-index:400;
    }
}
//ID Input
.id_input_field{
    display:block;
    position:relative;
    width:100%;
    .id_input{
        padding-right:144px;

    }
    .id_generate_button_wrap{
        position: absolute;
        top: -4px;
        right: 0;
        background: #fff;
        padding-left: 9px;
        padding-bottom: 6px;
        .id_generate_button{
            width: 109px;
            height: 32px;
            border-radius: 3px;
            color: #fff;
            display: block;
            position: relative;
            padding: 5px 2px 4px 39px;
            line-height: 12px;
            font-size: 11px;
            cursor: pointer;
            cursor:pointer;
            .machine_icon{
                position: absolute;
                top: 10px;
                left: 7px;
                &::before{
                    content:"\e91f";
                    font-family: 'icomoon';
                    font-size: 19px;
                }
            }
        }
    }
}

//Generate input
.generate_input_field{
    display:block;
    position:relative;
    width:100%;
    .generate_input{
        padding-right:144px;

    }
    .generate_button_wrap{
        position: absolute;
        top: -4px;
        right: 0;
        background: #fff;
        padding-left: 9px;
        padding-bottom: 6px;
        .generate_button{
            width: 109px;
            height: 32px;
            border-radius: 3px;
            color: #333;
            display: flex;
            position: relative;
            padding: 5px 5px 5px;
            line-height: 12px;
            font-size: 11px;
            cursor: pointer;
            border: 1px solid #707070;
            align-items: center;
            justify-content: center;
            .machine_icon{
                position: absolute;
                top: 10px;
                left: 7px;
                &::before{
                    content:"\e91f";
                    font-family: 'icomoon';
                    font-size: 19px;
                }
            }
        }
    }
}

.search-input{
    background: url("../../images/search-icon.png") no-repeat scroll left 7px transparent !important;
    padding-left: 19px !important;
}

#root .block input:-internal-autofill-selected {
    &.search-input{
        background: url("../../images/search-icon.png") no-repeat scroll left 4px transparent !important;
    }
}
.sm-size{
    font-size: 11px;
    padding: 4px 16px;
}

.form-icons{
    .set-icon{
        margin-left:5px;
        cursor: pointer;
        font-family:icomoon;
        font-size: 15px;
    }
}

.medical_input{
    width:296px !important;
}

/****Condition****/
.result-container fieldset, .modal fieldset{
    &:disabled{
        .form-row{
            input[type="text"],
            input[type="password"],
            select,
            textarea,
            .datepicker,
            .selectAutocompleteContainer .selectAutocompleteInput__control{
                border:none !important;
                background:#fff !important;
                font-size: 17px;
                font-weight: 600;
                color: #000;
                height: auto;
                margin-top: 0;
                min-height: 0;
                padding: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @media only screen and (max-width: 1600px){
                    font-size: 14px;
                }
            }
            .selectAutocompleteInput__indicators{
                display:none !important;
            }
            .selectAutocompleteInput__placeholder{
                color:    #fff;
            }
            textarea{
                padding:0 !important;
                font-weight: 600;
            }
            label{
                color:#666;
            }
        }
        .selectAutocompleteInput__single-value--is-disabled,
        .react-date-picker__inputGroup__input{
            color:#000;
            font-weight:600;
            font-size: 17px;
            top: 11px;
            @media only screen and (max-width: 1600px){
                font-size: 14px;
            }
        }
        .react-date-picker__inputGroup__year{
            width:auto !important;
        }

        .borderPadding {
            padding-top:28px;
        }

    }
}

//ID Input
.inline_input_field{
    display:block;
    position:relative;
    width:100%;
    .id_input{
        padding-right:144px;

    }
    .inline_button_wrap{
        position: absolute;
        top: -4px;
        right: 0;
        background: #fff;
        padding-left: 9px;
        padding-bottom: 6px;
        .inline_button{
            width: 109px;
            height: 32px;
            border-radius: 3px;
            display: block;
            position: relative;
            line-height: 12px;
            font-size: 11px;
            cursor: pointer;
            cursor:pointer;

        }
    }
}

.react-date-picker__inputGroup{
    font-size:$inputsize;
    @media only screen and (max-width: 1600px){
        font-size:$sm_inputsize;
    }
}

//Default Button

.default-btn{
    background: #CB1D23;
    color: #FFFFFF;
    border: 1px solid #A50202;
    border-radius: 30px;
    width: 90%;
    font-weight: normal;
}

.page-submit-btn{
    font-size: 12px;
    margin:13px auto 0;
    padding: 9px 0;
    display: block;
    overflow: hidden;
    width: 171px;
}

.anchor-text{
    color:$red;
    cursor: pointer;
}

.cred-text{
    color: #666;
    line-height: 17px;
}

.no-display{
    display: none;
}


.detailsCell {
    font-size: 14px;
    color:#444343;
    @media only screen and (max-width: 1600px){
        font-size: 11px;
    }
}

.side-content{
    label{
        color:#444343;
    }
}


.viewmodeSpan{
    font-size: 17px;
    font-weight:bold;
    color: #000;
    margin-top: 0;
    @media only screen and (max-width: 1600px){
        font-size: 14px;
    }
}

.specialNeeds-form, .checkboxBlock{
    .form-row{
        label{
            display:flex;
            align-items: center;
        }
    }
}
.tablebtn {
    text-align:left;
}

//inlineCheckbox

.nowWrap-checklist{
    display: flex;
    align-items: center;
    padding: 12px 0px 4px;
    margin-bottom: 0;
    width: 100%;
    label{
        input{
            margin-right:5px;
        }
        display: inline-flex;
        align-items: center;
        margin-right:15px;
    }
    @media screen and (max-width: 900px) {
        flex-wrap: wrap;
    }
}

.nowWrap-button{
    padding:10px 0;
    button{
        margin-right:10px;
    }
}

.time-picker{
    background: url("../../images/time-icon.png") no-repeat scroll 0 center transparent !important;
    padding-left: 25px !important;
    background-size: 14px !important;
}

.print-type-form{
    .form-row{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        margin-left:0;
        label{
            width: auto;
            margin:0 10px 0 0;
            padding: 0;
        }
        .selectAutocompleteContainer {
            width:100px;
        }
    }
}

.timerInput{
    width: 100%;
    position:relative;
    .timer-clear{
        position:absolute;
        right:0;
        cursor:pointer;
        top:5px;
        @media only screen and (max-width: 1600px) {
            top:6px;
        }
        &::before{
            content:"\e909";
            font-family: 'icomoon';
            font-size:12px;
            @media only screen and (max-width: 1600px) {
                font-size:10px;
            }
        }
    }
}

.time-picker-button-wrap{
    display:flex;
    justify-content:space-evenly;
    .picker-btn{
        width: 50%;
        text-align: center;
        align-items: center;
        padding: 11px 0;
        cursor: pointer;
        text-transform: uppercase;
        cursor: pointer;
        &.colorbtn{
            background:#0080FF;
            color:#fff;
        }
    }
}


.react-time-picker{
    border:none;
    border-bottom: 1px solid #D5D5D5;
    width:100%;
    display:flex;
    flex-direction: column;
    .react-time-picker__wrapper{
        border:none;
        .react-time-picker__button{
            padding:4px 0;
        }
        .react-time-picker__inputGroup{
            display: flex;
            flex-wrap: nowrap;
            text-align: center;
            .react-time-picker__inputGroup__leadingZero{
                display:none;
            }
            input{
                border:none;
                min-width:20px;
                height: auto;
                width:20px;
                text-align: center;
                margin: 0;
            }
            .react-time-picker__inputGroup__amPm{
                width:auto;
            }
            .react-time-picker__inputGroup__divider{
                display: flex;
                align-items: center;
            }

        }
    }
}

button.toolbtn{
    border:1px solid $tools;
    color:$tools;
}

.first-btn{
    margin-right:10px;
    margin-bottom:10px;
}
.clearSearch{
    position:relative;
}
.clearbutton{
    position:absolute;
    right:0;
    cursor:pointer;
    top:-1px;
    @media only screen and (max-width: 1600px) {
        top:1px;
    }
    &::before{
        content:"\e909";
        font-family: 'icomoon';
        font-size:12px;
        @media only screen and (max-width: 1600px) {
            font-size:10px;
        }
    }
}


.exportdrop{
    .selectAutocompleteContainer{
        max-width:184px;
    }
}

.selectAutocompleteInput__control .selectAutocompleteInput__multi-value{
    padding: 1px 3px !important;
    border-radius: 6PX;
    height: auto !important;
    align-items: center;
}