.tools-wrap {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.row-flex{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    max-width: 100%;
    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }
    
     
}
.col-left-container{
    width:28%;
    display: flex;
    margin: 0 10px 10px 0;
    flex-wrap: wrap;
    flex-direction: column;
    @media screen and (max-width: 1200px) {
        width:100%;
    }
}


.col-center-container{
    width:57%;
    display: flex;
    margin: 0 10px 10px 0;
    flex-wrap: wrap;
    flex-direction: column;
    @media screen and (max-width: 1200px) {
        width:100%;
    }
}

.col-right-container{
    display: flex;
    margin: 0 10px 10px 0;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1;
    @media screen and (max-width: 1200px) {
        width:100%;
    }
}

.col-inner-padding{
    padding:15px;
}

.col-full-flex{
    flex:1;
}

.views-wrap{
    display: flex;
    justify-content: space-between;
    align-items:unset;
    flex-direction: row;
    flex-wrap: nowrap;
    padding:7px 0 17px;
    margin-bottom: 11px;
    @media screen and (max-width: 1200px) {
        align-items: end;
    }
    .view-image{
        width:130px;
        height:125px;
        background-color:#EEE;
        margin-right:10px;
        @media screen and (max-width: 1600px) {
            width: 94px;
            height: 96px;
        }
        &.tools-img{
            background: url("../../images/tool-icon.png") no-repeat scroll  center #EEE ;
            background-size: 45%;
        }
        &.download-img{
            background: url("../../images/download-icon.png") no-repeat scroll  center #EEE ;
            background-size: 63%;
        }
    }
    .view-content-wrap{
        flex: 1;
        .view-title{
            font-weight: bold;
            margin-bottom: 7px;
        }
        .view-content{
            color:#707070;
            margin-bottom: 7px;
        }
        .view-button{
            button{
                white-space: nowrap;
                margin-bottom:10px;
            }
        }
    }
}

.center-align{
    justify-content: center;
}

.view-button{
    button{
        white-space: nowrap;
        margin-bottom:10px;
    }
}