.rc-time-picker{
    width:100%;
    .rc-time-picker-clear-icon{
        &:after{
            content:"\e909";
            font-family:"icomoon";
            font-size: 10px;
        }
    }
}
.rc-time-picker-panel{
    z-index: 5010  !important;
}
.rc-time-picker-panel-input-wrap{
    display:none !important;
}

.rc-time-picker-panel-inner{
    margin-top: 32px;
}

.flatpickr-input{
    display:none;
}

.rc-time-picker-input{
    border-radius: 0 !important;
}