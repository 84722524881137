.info-panel{
    display:flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top:26px;
    @media only screen and (max-width:699px){
        flex-direction: column;
    }
    &.calendarPanel{
        margin:0;
    }
    .panel-left{
        display:flex;
        width:70%;
        flex-wrap: wrap;
        @media only screen and (max-width:1699px){
            width:92%;
        }
        @media only screen and (max-width:699px){
            width:100%;
        }
        @media only screen and (min-width: 700px) and (max-width:1100px){
            width:100%;
        }
        .panel-set{
            margin-right: 3%;
            display: inline-flex;
            flex-wrap: wrap;
            @media only screen and (max-width:1100px) {
                display: flex;
                margin: 11px 0;
                width: 100%;
            }

            .id-wrap{
                white-space: nowrap;
                font-size: $lg_size;
                @media only screen and (max-width: 1600px) {
                    font-size:$sm_size;
                }
            }
            .data-col{
                width: 100%;
                display: block;
                font-size: $lg_label_head;
                font-weight:600;
                margin-bottom: 5px;
                min-width: 43px;
                line-height: 14px;
                @media only screen and (max-width: 1600px) {
                    font-size:$sm_label_head;
                }
                &.highligh-label{
                    font-size: $lg_label_head;
                    font-weight:600;
                    margin-bottom: 0;
                    max-width: 330px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    @media only screen and (max-width: 1600px) {
                        font-size:$sm_label_head;
                    }
                }
            }
            label{
                width: 100%;
                display: block;
                font-size: $lg_size;
                @media only screen and (max-width: 1600px) {
                    font-size:$sm_size;
                }
            }
            .selected-items{
                display: flex;
                padding: 4px 9px;
                background: #EEE;
                border-radius: 6px;
                align-items: center;
                @media only screen and (max-width: 1600px) {
                    padding:4px 9px;
                }
                input{
                    margin-right:6px;
                }
                .closesection{
                    margin-left:12px;
                    cursor: pointer;
                    line-height: 1;
                    &::before{
                        content:"\e909";
                        font-family: 'icomoon';
                        color: #000;
                        font-size: 8px;
                    }
                }
                label{
                    margin-bottom: 0;
                    cursor: pointer;
                    font-size:11px;
                }
            }
        }
        &.selected-view{
          width:100%;
          .panel-set{
            margin-right: 0.5%;
            margin-bottom: 7px;
          }
        }
    }
    .panel-right{
        display:flex;
        align-items: center;
        .edit-menu{
            &::before{
                content:"\e911";
                font-family: 'icomoon';
                font-size: 20px;
            }
        }
    }
    &.run-record{
        flex-wrap: nowrap;
        margin-bottom: 5px;
        .panel-left{
            flex:1;
            width:auto;
            flex-wrap: nowrap;
        }
    }

}