.switch{
    position: relative;
    height: 18px;
    width: 40px;
    display: inline-block;
    margin-bottom:0;
    input{
     display:none;
     &:checked + .slider{
       background:#0B6CC1;
       &:before{
        transform:translateX(22px);
       }
     }
     
    }
    .slider{
     position:absolute;
     cursor:pointer;
     top:0;
     left:0;
     bottom:0;
     right:0;
     background-color:#ccc;
     border-radius:70px;
     transition:0.4s;
        &::before{
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background: #fff;
            left: 1px;
            top: 1px;
        }
    }
}
   
.switchBlock{
    display:flex;
    align-items: center;
    .switch-label{
        font-size:$lg_size;
        margin-right:10px;
        @media only screen and (max-width: 1600px) {
            font-size: $sm_size;
        }
    }
}