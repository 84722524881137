.fluid-custom-container{
    display:flex;
    width:100%;
    flex-direction: column;
}

.cus-row{
    display:flex;
    width:100%;
}

.flexColumn{
    flex-direction: column;
}

.w100{
    width:100%;
}

.col-cus-5 {
    flex: 0 0 35%;
    max-width: 35%;
    padding: 0 15px;
    @media screen and (min-width: 1601px) {
        flex: 0 0 34%;
        max-width: 34%;
    }
}

.col-cus-7{
    flex: 0 0 64%;
    max-width: 64%;
    padding-right:15px;
    padding-left: 0;
    @media screen and (min-width: 1601px) {
        flex: 0 0 65%;
         max-width: 65%;
    }
}

.col-cus-12{
    display:flex;
    width:100%;
    padding: 0 15px;
}

 .col-flex-auto{
    flex-grow: 1;
    @media screen and (max-width: 1199px) {
        flex-grow: unset;
    }
 }

 .base-gap{
     padding-bottom:2.2%;
 }

 .flexColumnSet{
    display: flex;
    flex-direction: column;
 }

 .left-list-view{
    flex: 1;
    flex-direction: column;
    display: flex;
    position:relative;
    overflow:hidden;
    padding:15px;
 }
 .dashboard-block-outerwrap{
    flex: 1;
    flex-direction: column;
    position:relative;
    margin-top: 10px;
 }
 .leftTableFixed, .rightTableFixed{
    overflow: auto;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 1199px) {
        position:relative;
        max-height:500px;
    }
  }


 .right-list-view{
    flex: auto;
    flex-direction: column;
    display: flex;
    height: 50%;
    @media screen and (max-width: 900px) {
        height:auto;
    }
 }

 .block-row-container{
     margin:10px 5px;
 }

 .content-data{
     .data-slot{
        margin:7px 0;
     }
     .data-result{
         font-weight:600;
     }
 }

 .row-form-spacer{
     margin-left:-5px;
     margin-right:-5px;
 }

 .button-spacer{
     margin-top:14px;
 }

 .transfer{
    background: url("../../images/transfer.png") no-repeat scroll 0 0 transparent;
    width: 24px;
    height: 14px;
    display: block;
    overflow: hidden;
    margin-top: 18px;
 }

 .form-block{
    width:100%;
    display:block;
    overflow:hidden;
    padding:0 5px;
}

.modal-row{
    padding:0 6px;
}

.row-page{
    flex: 0 0 100%;
    max-width: 100%;
    padding:0;
    display:flex;
    flex-wrap: nowrap;
    @media screen and (max-width: 1270px) {
        flex-wrap: wrap;
    }
}
//RUN ASSIGNMENT COLUMN SETTINGS
.topTitle{
    height: 44px;
    width: 100%;
    background: #FFF;
    padding: 8px 22px !important;
    @media screen and (max-width: 600px) {
        height:auto;
    }

}
.topHeading-margin{
    align-items: center;
    display: flex;
    h2{
        margin:0;
        @media screen and (max-width: 600px) {
            margin-bottom:10px;
        }
    }
}
.color-title{
    color:#E20062;
}
.run-col-40{
    width: 44%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    @media screen and (max-width:1270px) {
        width: 100%;
        margin-right:0;
    }
  
}
.run-col-50{
    flex: 1;
    @media screen and (max-width:1199px) {
        width: 100%;
        margin-right:0;
        flex:auto;
    }
}
.run-col-60{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1199px) {
        width: 100%;
        margin-right:0;
    }
}

.run-col-100{
    width: 100%;
}

.run-margin{
    margin-bottom:10px;
}
.run-right-margin{
    margin-right:10px;
    @media screen and (max-width:1270px) {
        margin-right:0;
    }
}

.st-record{
    height:auto;
    width: 100%;
    .info-panel{width:100%;}
    @media screen and (max-width:1199px) {
        height:auto;
        .info-panel{
            &.run-record .panel-left{
                flex-wrap: wrap;
                .data-col.highligh-label{
                    flex-wrap: wrap;
                    max-width: 100%;
                }
            }
        }
    }
}

.assing-details-block{
    height:auto;
    width: 100%;
    flex:1;
    @media screen and (max-width:1199px) {
        width: 100%;
        margin-right:0;
    }
}


.selected-details-block{
    width: 32%;
    min-width:345px;
    @media screen and (max-width:1600px) {
        width: 36%;
    }
    @media only screen and (max-width: 600px){
        min-width:100%;
    }
}
.program-details-block{
    flex: 1;
}

.selected-details-block, .program-details-block{
    height:auto;
    @media screen and (max-width:1199px) {
        width: 100%;
        margin-right:0;
    }
}
.scheduleDays{
    width: 100%;
}

.nowwrapDiv{
    display:flex;
    flex-wrap: nowrap;
    width: 100%;
    @media screen and (max-width: 1199px) {
        flex-wrap: wrap;
    }
}

.general-check{
    height:auto;
}



.run-padding{
    padding:15px 22px;
}

.block-wrapper{
    margin: 16px 0 0;
    display: flex;
    flex-direction: column;
    .data-row-card{
        border-bottom: 1px solid #D5D5D5;
        padding: 11px 0;
        width:100%;
    }
}

.col-cus-lg-p1{
    padding:0 15px;
    // width: 347px;
    @media screen and (max-width: 999px) {
        width:100%;
        padding:0 15px;
    }
    @media screen and (max-width: 1217px) {
        margin-right:50%;
    }
    @media screen and (max-width: 500px) {
        margin-right:0;
    }
}
.col-cus-lg-p2{
    padding:0 15px;
    width: auto;
    &.first{
        @media screen and (max-width: 1217px) {
            padding-left:10px;
        }
    }
    @media screen and (max-width: 1260px) {
        margin-left:0;
    }
    @media screen and (max-width: 999px) {
        width:100%;
        padding:0 10px;
    }
}
.col-cus-lg-p3{
    padding:0 15px;
    width: 275px;
    margin-left: 28px;
    @media screen and (max-width: 1260px) {
        margin-left:0;
    }
    @media screen and (max-width: 999px) {
        width:100%;
        padding:0 15px;
    }
}

.searchBlock{
    .runSearch{
        min-width:200px;
    }
}

.col-230{
    max-width:248px;
    width:235px;
    @media only screen and (max-width: 992px) {
        width:100%;
        max-width:100%;
    }
}

.col-center-fill{
    flex:1;
}

.flex-panel-box {
    display:flex;
}

.no-overflow{
    overflow:hidden;
}

/***Set height****/

.set-medium-height{
    max-height: calc(100vh - (100px + 150px));
}

.option-list-container{
    max-height: calc(100vh - (100px + 332px));
    overflow:auto;
}

.row-section-single{
    margin: 28px -10px 0;
    margin-left: -10px;
    display: flex;
    width: 100%;
}

.now-wrap-set{
    flex-wrap: nowrap;
    @media only screen and (max-width: 992px) {
        width:100%;
        max-width:100%;
        flex-wrap: wrap;
    }
}