//Module Template

.dashboard_page {
    h2{
        color:$red;
        margin-bottom:20px;
    }
    .block_heading_container{
        .list_Icon{
            color:$red;
        }
    }

}
.title{
    display:flex;
    align-items: center;
    position:relative;
}

.forgotpassword{
    h2{
        color:$red;
    }
}

.studentPage{
    .tp-color, .linkSpan{
        color:#D7265C;
    }
    .title{
        h1{
            color:$student;
        }
        .page_icon{
            &::before{
                content:"\e91a";
                font-family: 'icomoon';
                color:$student;
                font-size:20px;
                display:inline-block;
                vertical-align:top;
                @media screen and (max-width: 1600px) {
                    font-size:16px;
                }
            }
        }
        .back-link{
            cursor: pointer;
        }
    }
    .card-title{
        color:#D7265C;
    }
    h4{
        color:#D7265C;
    }
    .id_generate_button{
        background:#D7265C;
    }
    .block_heading_menu{
        .listMenu{
            &.active{
                color:#D7265C;
            }
        }
    }
    .left_menu_block{
        .menu-item {
            &:hover{
                .menu-icon-item, .menu_title{
                    color:#D7265C;
                }
            }
        }
    }
    .color-btn{
        background:#D7265C;
        color:#fff;
        border:1px solid #B7204E;
    }
    .add-filter, .base-highligh-label, .priorityHead{
        color:#D7265C;
    }
    .pagination{
            li{
                &.active{
                    a{
                      background:#D7265C;
                    }
                }
            }
    }
    .panel-heading{
        color:#D7265C;
    }

}
//STAFF
.staffPage{
    .tp-color, .linkSpan{
        color:$staff;
    }
    .title{
        h1{
            color:$staff;
        }
        .page_icon{
            &::before{
                content:"\e93c";
                font-family: 'icomoon';
                color:$staff;
                font-size:26px;
                display:inline-block;
                vertical-align:top;
                @media screen and (max-width: 1600px) {
                    font-size:18px;
                }
            }
        }
    }

    .card-title{
        color:$staff;
    }
    h4{
        color:$staff;
    }
    .id_generate_button{
        background:$staff;
    }
    .block_heading_menu{
        .listMenu{
            &.active{
                color:$staff;
            }
        }
    }
    .left_menu_block{
        .menu-item {
            &:hover{
                .menu-icon-item, .menu_title{
                    color:$staff;
                }
            }
        }
    }
    .color-btn{
        background:$staff;
        color:#fff;
        border:1px solid $staff;
    }
    .add-filter, .base-highligh-label, .priorityHead{
        color:$staff;
    }
    .pagination{
            li{
                &.active{
                    a{
                      background:$staff;
                    }
                }
            }
    }
    .panel-heading{
        color:$staff;
    }
    .tab-container .tab-menu .tab-menu-li.active {
        color: $staff;
    }

}
//REPORTS


.reportPage{
    .tp-color, .linkSpan{
        color:$report;
    }
    .title{
        h1{
            color:$report;
        }
        .page_icon{
            &::before{
                content:"\e90c";
                font-family: 'icomoon';
                color:$report;
                font-size:20px;
                display:inline-block;
                vertical-align:top;
                @media screen and (max-width: 1600px) {
                    font-size:16px;
                }
            }
        }
    }
    .card-title{
        color:$report;
    }
    h4{
        color:$report;
    }
    .id_generate_button{
        background:$report;
    }
    .block_heading_menu{
        .listMenu{
            &.active{
                color:$report;
            }
        }
    }
    .left_menu_block{
        .menu-item {
            &:hover{
                .menu-icon-item, .menu_title{
                    color:$report;
                }
            }
        }
    }
    .color-btn{
        background:$report;
        color:#fff;
        border:1px solid $report;
    }
    .add-filter, .base-highligh-label, .priorityHead{
        color:$report;
    }
    .pagination{
            li{
                &.active{
                    a{
                      background:$report;
                    }
                }
            }
    }
    .panel-heading{
        color:$report;
    }
    .tab-container .tab-menu .tab-menu-li.active {
        color: $report;
    }

}
//RUN

.runPage{
    .tp-color, .linkSpan{
        color:$run;
    }
    .title{
        h1{
            color:$run;
        }
        .page_icon{
            &::before{
                content:"\e914";
                font-family: 'icomoon';
                color:$run;
                font-size:20px;
                display:inline-block;
                vertical-align:top;
                @media screen and (max-width: 1600px) {
                    font-size:16px;
                }
            }
        }
    }
    .card-title{
        color:$run;
    }
    h4{
        color:$run;
    }
    .id_generate_button{
        background:$run;
    }
    .block_heading_menu{
        .listMenu{
            &.active{
                color:$run;
            }
        }
    }
    .left_menu_block{
        .menu-item {
            &:hover{
                .menu-icon-item, .menu_title{
                    color:$run;
                }
            }
        }
    }
    .color-btn{
        background:$run;
        color:#fff;
        border:1px solid $run;
    }
    .add-filter, .base-highligh-label, .priorityHead{
        color:$run;
    }
    .pagination{
            li{
                &.active{
                    a{
                      background:$run;
                    }
                }
            }
    }
    .panel-heading{
        color:$run;
    }
    .tab-container .tab-menu .tab-menu-li.active {
        color: $run;
    }

}
//SCHOOL PAGE
.schoolDistrictPage, .schoolPage, .programPage{
    .tp-color, .linkSpan{
        color:$school;
    }
    .title{
        h1{
            color:$school;
        }
        .page_icon{
            &::before{
                content:"\e927";
                font-family: 'icomoon';
                color:$school;
                font-size:25px;
                display:inline-block;
                vertical-align:top;
                @media screen and (max-width: 1600px) {
                    font-size:16px;
                }
            }
        }
    }
    h4{
        color:#0B6CC1;
    }
    .id_generate_button{
        background:#0B6CC1;
    }
    .left_menu_block{
        .menu-item {
            &:hover{
                .menu-icon-item, .menu_title{
                    color:#0B6CC1;
                }
            }
        }
    }
    .color-btn{
        background:#0B6CC1;
        color:#fff;
        border:1px solid #0B6CC1;
    }
    .add-filter, .base-highligh-label{
        color:#0B6CC1;
    }
    .pagination{
            li{
                &.active{
                    a{
                      background:#0B6CC1;
                    }
                }
            }
    }

}
.userPage{
    .tp-color, .linkSpan{
        color:$user;
    }
    .title{
        h1{
            color:$user;
        }
        .page_icon{
            &::before{
                content:"\e92b";
                font-family: 'icomoon';
                color:$user;
                font-size:25px;
                display:inline-block;
                vertical-align:top;
                @media screen and (max-width: 1600px) {
                    font-size:16px;
                }
            }
        }

    }
    h4{
        color:$user;
    }
    .id_generate_button{
        background:$user;
    }
    .left_menu_block{
        .menu-item {
            &:hover{
                .menu-icon-item, .menu_title{
                    color:$user;
                }
            }
        }
    }
    .color-btn{
        background:$user;
        color:#fff;
        border:1px solid $user;
    }
    .add-filter, .base-highligh-label{
        color:$user;
    }
    .pagination{
            li{
                &.active{
                    a{
                      background:$user;
                    }
                }
            }
    }

}


.schoolPage{
    .title{
        h1{
            color:$school;
        }
        .page_icon{
            &::before{
                content:"\e915";
                font-family: 'icomoon';
                margin-bottom: 5px;
            }
        }
    }
}

 
.calendarPage{
    .tp-color, .linkSpan{
        color:$calendar;
    }
    .title{
        h1{
            color:$calendar;
        }
        .page_icon{
            &::before{
                content:"\e905";
                font-family: 'icomoon';
                color:$calendar;
                font-size:25px;
                display:inline-block;
                vertical-align:top;
                @media screen and (max-width: 1600px) {
                    font-size:16px;
                }
            }
        }

    }
    h4{
        color:$calendar;
    }
    .id_generate_button{
        background:$calendar;
    }
    .left_menu_block{
        .menu-item {
            &:hover{
                .menu-icon-item, .menu_title{
                    color:$calendar;
                }
            }
        }
    }
    .color-btn{
        background:$calendar;
        color:#fff;
        border:1px solid $calendar;
    }
    .add-filter, .base-highligh-label{
        color:$calendar;
    }
    .pagination{
            li{
                &.active{
                    a{
                      background:$calendar;
                    }
                }
            }
    }

}

.vehiclePage, .contractPage{
    .tp-color, .linkSpan{
        color:$vehicle;
    }
    .title{
        h1{
            color:$vehicle;
        }
        .page_icon{
            &::before{
                content:"\e904";
                font-family: 'icomoon';
                color:$vehicle;
                font-size:20px;
                display:inline-block;
                vertical-align:top;
                @media screen and (max-width: 1600px) {
                    font-size:16px;
                }
            }
        }
    }
    .card-title{
        color:$vehicle;
    }
    h4{
        color:#e99803;
    }
    .id_generate_button{
        background:$vehicle;
    }
    .block_heading_menu{
        .listMenu{
            &.active{
                color:$vehicle;
            }
        }
    }
    .left_menu_block{
        .menu-item {
            &:hover{
                .menu-icon-item, .menu_title{
                    color:$vehicle;
                }
            }
        }
    }
    .color-btn{
        background:$vehicle;
        color:#fff;
        border:1px solid $vehicle;;
    }
    .add-filter, .base-highligh-label, .priorityHead{
        color:$vehicle;
    }
    .pagination{
            li{
                &.active{
                    a{
                      background:$vehicle;;
                    }
                }
            }
    }
    .panel-heading{
        color:$vehicle;;
    }

}

#contractPage{
    .page_icon{
        &::before{
            content:"\e93a" !important;
            font-family: 'icomoon';
            color:$vehicle;
            font-size:26px;
            display:inline-block;
            vertical-align:top;
            @media screen and (max-width: 1600px) {
                font-size:16px;
            }
        }
    }
}
.programPage{
    .page_icon{
        &::before{
            content:"\e93d" !important;
            font-family: 'icomoon';
            color:$school;
            font-size:28px;
            display:inline-block;
            vertical-align:top;
            font-weight:bold;
            @media screen and (max-width: 1600px) {
                font-size:18px;
            }
        }
    }
}

.toolsPage{
    .tp-color, .linkSpan{
        color:$tools;
    }
    .title{
        h1{
            color:$tools;
        }
        .page_icon{
            &::before{
                content:"\e918";
                font-family: 'icomoon';
                color:$tools;
                font-size:25px;
                display:inline-block;
                vertical-align:top;
                @media screen and (max-width: 1600px) {
                    font-size:16px;
                }
            }
        }

    }
    h4{
        color:$tools;
    }
    .id_generate_button{
        background:$tools;
    }
    .left_menu_block{
        .menu-item {
            &:hover{
                .menu-icon-item, .menu_title{
                    color:$mileage;
                }
            }
        }
    }
    .color-btn{
        background:$tools;
        color:#fff;
        border:1px solid $tools;
    }
    .add-filter, .base-highligh-label{
        color:$tools;
    }
    .pagination{
            li{
                &.active{
                    a{
                      background:$tools;
                    }
                }
            }
    }

}
//Mileage
.mileagePage{
    .tp-color, .linkSpan{
        color:$mileage;
    }
    .title{
        h1{
            color:$mileage;
        }
        .page_icon{
            &::before{
                content:"\e910";
                font-family: 'icomoon';
                color:$mileage;
                font-size:25px;
                display:inline-block;
                vertical-align:top;
                @media screen and (max-width: 1600px) {
                    font-size:16px;
                }
            }
        }

    }
    h4{
        color:$mileage;
    }
    .id_generate_button{
        background:$mileage;
    }
    .left_menu_block{
        .menu-item {
            &:hover{
                .menu-icon-item, .menu_title{
                    color:$mileage;
                }
            }
        }
    }
    .color-btn{
        background:$mileage;
        color:#fff;
        border:1px solid $mileage;
    }
    .add-filter, .base-highligh-label{
        color:$mileage;
    }
    .pagination{
            li{
                &.active{
                    a{
                      background:$mileage;
                    }
                }
            }
    }
    .no-bpadding{
        .form-row{
            padding-bottom: 0;
        }
    }

}


//Houses
.housePage{
    .tp-color, .linkSpan{
        color:$houses;
    }
    .title{
        h1{
            color:$houses;
        }
        .page_icon{
            &::before{
                content:"\e90d";
                font-family: 'icomoon';
                color:$houses;
                font-size:25px;
                display:inline-block;
                vertical-align:top;
                @media screen and (max-width: 1600px) {
                    font-size:16px;
                }
            }
        }

    }
    h4{
        color:$houses;
    }
    .id_generate_button{
        background:$houses;
    }
    .left_menu_block{
        .menu-item {
            &:hover{
                .menu-icon-item, .menu_title{
                    color:$houses;
                }
            }
        }
    }
    .color-btn{
        background:$houses;
        color:#fff;
        border:1px solid $houses;
    }
    .add-filter, .base-highligh-label{
        color:$houses;
    }
    .pagination{
            li{
                &.active{
                    a{
                      background:$houses;
                    }
                }
            }
    }

}



//Stop
.stopPage{
    .tp-color, .linkSpan{
        color:$stop;
    }
    .title{
        h1{
            color:$stop;
        }
        .page_icon{
            &::before{
                content:"\e916";
                font-family: 'icomoon';
                color:$stop;
                font-size:25px;
                display:inline-block;
                vertical-align:top;
                @media screen and (max-width: 1600px) {
                    font-size:16px;
                }
            }
        }

    }
    h4{
        color:$houses;
    }
    .id_generate_button{
        background:$houses;
    }
    .left_menu_block{
        .menu-item {
            &:hover{
                .menu-icon-item, .menu_title{
                    color:$stop;
                }
            }
        }
    }
    .color-btn{
        background:$stop;
        color:#fff;
        border:1px solid $stop;
    }
    .add-filter, .base-highligh-label{
        color:$stop;
    }
    .pagination{
            li{
                &.active{
                    a{
                      background:$stop;
                    }
                }
            }
    }

}


//Move Groups
.movegroupPage{
    .tp-color, .linkSpan{
        color:$movegroup;
    }
    .title{
        h1{
            color:$movegroup;
        }
        .page_icon{
            &::before{
                content:"\e91e";
                font-family: 'icomoon';
                color:$movegroup;
                font-size:25px;
                display:inline-block;
                vertical-align:top;
                @media screen and (max-width: 1600px) {
                    font-size:16px;
                }
            }
        }

    }
    h4{
        color:$movegroup;
    }
    .id_generate_button{
        background:$houses;
    }
    .left_menu_block{
        .menu-item {
            &:hover{
                .menu-icon-item, .menu_title{
                    color:$stop;
                }
            }
        }
    }
    .color-btn{
        background:$movegroup;
        color:#fff;
        border:1px solid $movegroup;
    }
    .add-filter, .base-highligh-label{
        color:$movegroup;
    }
    .pagination{
            li{
                &.active{
                    a{
                      background:$movegroup;
                    }
                }
            }
    }

}

.menu-item{
    &.studentmenu.active{
        background:$student;
    }
    &.schoolmenu.active, &.districtmenu.active{
        background:$school;
    }
    &.calendarmenu.active{
        background:$calendar;
    }
    &.vehiclemenu.active{
        background:$vehicle;
    }
    &.staffmenu.active{
        background:$staff;
    }
    &.mileagemenu.active{
        background:$mileage;
    }
    &.runmenu.active{
        background:$run;
    }
    &.reportmenu.active{
        background:$report;
    }
    &.toolsmenu.active{
        background:$tools;
    }
    &.housesmenu.active{
        background:$houses;
    }
    &.stopmenu.active{
        background:$stop;
    }
    &.movegroupmenu.active{
        background:$movegroup;
    }

    
}

.page_icon{
    font-family: icomoon;
    margin-right: 9px;
}

