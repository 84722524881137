//Set Margins

.mr_0{
    margin-right:0;
}

.pr_0{
    padding-right:0;
}
.pr-10{
    padding-right:10px !important;
}
.pr-15{
    padding-right:15px !important;
}
.pr-20{
    padding-right:20px !important;
}
.mt10{
    margin-top:10px;
}
.mb10{
    margin-bottom:10px;
}

.mb36{
    margin-bottom:36px;
}

.cus-pr-gap-filler{
    padding-right:4%;
}

.cus-pr-gap-filler_with_border{
    padding-right:2.8%;   
    border-right:1px solid #E3E3E3;
}
.cus-plr-gap-filler{
    padding-left: 2.8%;
    padding-right: 1%;
}

.mt-cs-block{
    margin-top:16px;
}

.inputmargin{
    margin-top: 8px;
    margin-bottom: 8px;
}

.align-two-side{
    justify-content: space-between;
    display:flex;
}

.right-align{
    margin-left: auto;
}