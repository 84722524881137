  .pagination{
    display:flex;
    justify-content: center;
    margin:45px 0 ;
    width:100%;
      li{
          margin: 0 4px;        
          a{
            padding: 3px 7px;
            font-size: 13px;
            display: block;
            border-radius: 67px;
            background: #eee;
            -webkit-box-shadow: 0px 2px 3px 0px #ccc;
            -moz-box-shadow:    0px 2px 3px 0px #ccc;
            box-shadow:         0px 2px 3px 0px #ccc;
            
          }
          &.active{
            a{
              color:#fff;
              -webkit-box-shadow: 0px 2px 3px 0px #ccc;
              -moz-box-shadow:    0px 2px 3px 0px #ccc;
              box-shadow:         0px 2px 3px 0px #ccc;
              &:hover{
                color:#fff;
              }
            }
          }
          &.disabled{
            a{
              color: #ccc;
              pointer-events: none;
            }
          }
      }
  }
  
  .pager_items{
    display:none;
  }