//Search

.search_row{
    display:flex;
    width:100%;
    flex-wrap: wrap;
    justify-content: space-between;    
    .search-filters{
        flex: 0 0 76%;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-wrap: wrap;
        .search-fields{
            flex: 0 0 47%;
            max-width: 100%;
            display: flex;
            margin-right: 1.5%;
            flex-wrap: wrap;
            position:relative;
            padding:13px 0px;
            .search-col{
                width: 46%;
                margin-right: 4%;
            }
            .search_delete{
                position: absolute;
                bottom: 0;
                margin: 0;
                height: 100%;
                right: 0;
                .search_delete_icon{
                    margin-top: 39px;
                    display: block;
                    cursor: pointer;
                    &::before{
                        content:"\e909";
                        font-family: 'icomoon';
                        font-size: 14px;
                        @media only screen and (max-width: 1600px) {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
        .add-filter{
            display: flex;
            align-items: center;
            text-decoration: underline;
            cursor: pointer;
            width: 100%;
            margin-bottom:20px;
        }
        
        
    }
    .search-action{
        display: flex;
        align-items: center;
        height: 92px;
        width: 212px;
        button,input{
            margin-left: 4%;
            white-space: nowrap;
            width: 100px;
        }
    }
    
}

.searchBlock{
    margin: 40px 0 16px;
    padding: 0 8px;
    display: block;
    .search_button{
        margin-top:15px;
    }
}

.contractSearch{
        max-width: 25%;
        flex: 0 0 18%;
        @media only screen and (max-width: 1600px) {
            flex: 0 0 20%;
        }
}