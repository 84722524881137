//Add Popup

.addPopup{
   // display:none;
    position:absolute;
    background:#fff;
    width:100%;
    height:auto;
    z-index:50;
    top:0;
    left:0;
    padding: 35px 45px;
    -webkit-box-shadow: 0px 2px 7px 0px #ccc;
    -moz-box-shadow:    0px 2px 7px 0px #ccc;
    box-shadow:         0px 2px 7px 0px #ccc;
    overflow: auto;
    @media only screen and (max-width: 1025px) {
        position:fixed;
        top:0;
        padding:24px 26px;
        width:100%;
        height:100%;
        z-index:4000;

    }
    .addForm{
        margin-top: 30px;
        padding: 0 6px;
        .addpopupSave {
            width:160px;
            @media only screen and (max-width: 1025px) {
                margin-top:20px;
            }
        }
    }
    .close{
        position:abolute;
        right:10px;
        top:10px;
        cursor:pointer;
        &::before{
            content:"\e909";
            font-family: 'icomoon';
            @media only screen and (max-width: 1600px) {
                font-size:16px;
            }
        }
    }
}

.addpopupSave {
    width:160px;
}