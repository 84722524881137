//Dashboard

.mapContainer{
    display: flex;
    background: #000;
    height: 44%;
    min-height:200px;
    overflow:hidden;
    position: relative;
    @media screen and (max-width: 1366px) and (min-height: 799px) {
        height:30%;
    }
    @media screen and (max-width: 1280px) and (min-height: 799px) {
        height:40%;
    }
    @media screen and (max-width: 1199px) {
        max-height: 247px;
    }
    iframe{
        width:100%;
        height:100%;
        position:absolute;
        left:0;
        top:0;

    }
}

.toprow{
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: 1800px) {
        height: 70px;
    }
    @media screen and (max-width: 1600px) {
        height: 48px;
    }
}

.mapWrap{
    background: rgba(0, 26, 55, 0.8);
    position: absolute;
    z-index: 100;
    color: #fff;
    padding: 9px 10px;
    font-size: 11px;
    .map_icon{
        display: inline-block;
        vertical-align: top;
        margin-right: 6px;
        &::before{
            content:"\e90f";
            font-size:13px;
            color:#fff;
            font-family: 'icomoon';
        }
    }
    .map_title{
       color: #fff;
       display: inline-block;
       vertical-align: top;
    }
}
.pac-container {
    z-index: 1051 !important;
}
.dashboard_page .page_icon{
    display:none;
}

.update-district-icon{
    background:url("../../images/dropdown_icon.png") no-repeat scroll center transparent;
    width: 16px;
    height: 13px;
    margin-left: 5px;
    opacity: 0.8;
    cursor: pointer;
}

.schoolDistrcit-defined{
    box-shadow: 0px 2px 5px 0px #666;
    position: absolute;
    background: #fff;
    bottom: -85px;
    left: 0;
    width: 100%;
    z-index: 200;
    min-width: 200px;
    padding: 16px 12px;
    border-radius: 3px;
    // display:none;
}

.map-optional-container{
    background:url("../../images/map-image.jpg") no-repeat scroll center #000;
    background-size:cover;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    .map-enable-btn{
        background:rgba(0,0,0,0.3);
        color:#fff;
    }
}
.directionsPanel{
    float: right;
width: 30%;
height: 400px;
overflow-y: auto;
// border-bottom: 1px solid #666;
margin-bottom: 42px;
      @media print {
        //   position: absolute;
        float: left;
          height: auto;
          width:100%;
        //   overflow: visible;
          table, tr, td, th, tbody, thead, tfoot { page-break-inside: avoid !important; }
          table {
            // table-layout: fixed;
            width: 100%;
              tbody{
                  width: 100%;
                tr{
                    width: 100%;
                    td{
                      padding: 10px;
                    }


                }
              }
          }
      }
 }
 .directionsPanelContainer{
     @media print {
        position:relative;
        display:table;
        table-layout:fixed;
        // padding-top:20px;
        display: block;
        // margin: 40px 40px 40px 40px;
        // body{
        //     background:#fff !important;
        // }
     }

 }