footer{
    display:flex;
    width:100%;
    padding:10px 20px;
    justify-content: space-between;
    background:#fff;
    align-items: center;
    @media screen and (max-width: 1600px) {
        padding:6px 20px;
    }
    @media screen and (max-width: 1119px) {
        flex-wrap: wrap;
        flex-direction: column;
    }
    .ams_logo{
        width:258px;
        @media screen and (max-width: 1600px) {
            width: 213px;
        }
        @media screen and (max-width: 1119px) {
            width: 79%;
            margin: 10px auto 0;
            max-width: 266px;
            text-align: center;
        }
        img{
            max-width:100%;
            height:auto;
            @media screen and (max-width: 1119px) {
                margin: auto;
            }
        }
    }
    .version_text{
        @media screen and (max-width: 1119px) {
            font-size: 12px;
            margin: 5px 0;
            text-align: center;
        }
    }
    .footer_components{
        text-align: right;
        @media screen and (max-width: 1600px) {
            font-size:11px;
        }
        @media screen and (max-width: 1119px) {
            font-size: 11px;
            margin-bottom: 20px;
            text-align: center;
        }
    }
}