.left_menu_block{
    background: #3B3A3A;
    position: fixed;
    width: 40px;
    height: auto;
    left: 0;
    margin-top:0;
    z-index: 500;
    padding: 1% 0;
    overflow: hidden;
    transition: width 0.3s;
    @media screen and (max-width: 1600px) {
        width:33px;
    }

    .menuSwitch{
        .openSwitch{
            padding: 2px;
            width: 39px;
            display: block;
            text-align: center;
            cursor: pointer;
            left: 5px;
            // margin-bottom: 22px;
            @media screen and (max-width: 1600px) {
                width:33px;
            }
            &::before{
                content:"\e920";
                font-family: 'icomoon';
                color:#fff;
                font-size: 15px;
                @media screen and (max-width: 1600px) {
                    font-size:13px;
                }
            }
        }
        .closeSwitch{
            padding: 2px;
            width: 39px;
            display: none;
            text-align: center;
            cursor: pointer;
            left: 5px;
            // margin-bottom: 22px;
            margin-left: auto;
            &::before{
                content:"\e909";
                font-family: 'icomoon';
                color:#fff;
                font-size: 15px;
                @media screen and (max-width: 1600px) {
                    font-size:13px;
                }
            }
        }
    }
    &.active{
        width:190px;
        .openSwitch{
            display:none;
        }
        .closeSwitch{
            display:block;
        }
    }
    .menu-item{
        padding: 9px 0 9px 45px;
        margin: 8px 0;
        cursor: pointer;
        display: block;
        width: 190px;
        position: relative;
        color:#fff;
        opacity:0.9;
        &:hover{

                opacity:1;

        }
        @media screen and (max-width: 1600px) {
            padding: 7px 0 7px 37px;
            margin: 7px 0;
        }
        .menu_title{
            @media screen and (max-width: 1600px) {
                font-size:11px;
            }
        }
        .menu-icon-item{
            font-family: icomoon;
            color: #fff;
            position: absolute;
            left: 5px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            height: 19px;
            width: 29px;
            text-align: center;
            @media screen and (max-width: 1600px) {
                left: 1.8px;
            }
            &.dashboard-menu{
                &::before{
                    content:"\e917";
                    font-family: 'icomoon';
                    font-size:17px;
                    @media screen and (max-width: 1600px) {
                        font-size:15px;
                    }

                }
            }
            &.student-menu{
                &::before{
                    content:"\e91a";
                    font-family: 'icomoon';
                    font-size:19px;
                    @media screen and (max-width: 1600px) {
                        font-size:17px;
                    }

                }
            }
            &.schooldistrict-menu{
                &::before{
                    content:"\e927";
                    font-size:17px;
                    @media screen and (max-width: 1600px) {
                        font-size:15px;
                    }
                }
            }
            &.school-menu{
                &::before{
                    content:"\e915";
                    font-family: 'icomoon';
                    font-size:17px;
                    @media screen and (max-width: 1600px) {
                        font-size:15px;
                    }
                }
            }
            &.staff-menu{
                &::before{
                    content:"\e93c";
                    font-family: 'icomoon';
                    font-size:19px;
                    @media screen and (max-width: 1600px) {
                        font-size:17px;
                    }

                }
            }
            &.run-menu{
                &::before{
                    content:"\e914";
                    font-family: 'icomoon';
                    font-size:17px;
                    @media screen and (max-width: 1600px) {
                        font-size:15px;
                    }

                }
            }
            &.tools-menu{
                &::before{
                    content:"\e918";
                    font-family: 'icomoon';
                    font-size:17px;
                    @media screen and (max-width: 1600px) {
                        font-size:15px;
                    }

                }
            }
            &.report-menu{
                &::before{
                    content:"\e90c";
                    font-family: 'icomoon';
                    font-size:17px;
                    @media screen and (max-width: 1600px) {
                        font-size:15px;
                    }

                }
            }
            &.calendar-menu{
                &::before{
                    content:"\e905";
                    font-family: 'icomoon';
                    font-size:17px;
                    @media screen and (max-width: 1600px) {
                        font-size:15px;
                    }

                }
            }
            &.stop-menu{
                &::before{
                    content:"\e916";
                    font-family: 'icomoon';
                    font-size:23px;
                    @media screen and (max-width: 1600px) {
                        font-size: 21px;
                    }
                }
            }
            &.houses-menu{
                &::before{
                    content:"\e90d";
                    font-family: 'icomoon';
                    font-size:17px;
                    @media screen and (max-width: 1600px) {
                        font-size: 15px;
                    }
                }
            }
            &.vehicle-menu{
                &::before{
                    content:"\e904";
                    font-family: 'icomoon';
                    font-size:17px;
                    @media screen and (max-width: 1600px) {
                        font-size: 15px;
                    }
                }
            }
            &.tools-menu{
                &::before{
                    content:"\e918";
                    font-family: 'icomoon';
                    font-size:17px;
                    @media screen and (max-width: 1600px) {
                        font-size: 15px;
                    }
                }
            }
            &.mileage-menu{
                &::before{
                    content:"\e910";
                    font-family: 'icomoon';
                    font-size:17px;
                    @media screen and (max-width: 1600px) {
                        font-size: 15px;
                    }
                }
            }
            &.movegroup-menu{
                &::before{
                    content: "\e91e";
                    font-family: 'icomoon';
                    font-size:17px;
                    @media screen and (max-width: 1600px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }

}