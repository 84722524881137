
.wizard-icons{
    font-size:20px;
    margin-left:7px;
    cursor:pointer;
}

.addIcon{
    &::before{
        content:"\e902";
        font-family: 'icomoon';
    }
}

.deleteIcon, .delete-menu{
    &::before{
        content:"\e90a";
        font-family: 'icomoon';
    }
}

.edit-button{
    &::before{
        content:"\e91c";
        font-family: 'icomoon';
    }
    &.wizard-icons{
        font-size:22px;
    }
}


.clone-button{
    &::before{
        content:"\e900";
        font-family: 'icomoon';
    }
}

.save-menu{
    &::before{
        content:"\e907";
        font-family: 'icomoon';
        color:#1485DB;
    }
}

.cancel-menu{
    &::before{
        content:"\e906";
        font-family: 'icomoon';
        color:#E65555;
    }
}

.np-menu{
    &::before{
        content:"\e936";
        font-family: 'icomoon';
    }
    &.wizard-icons{
        font-size:20px;
    }
}

.print_menu{
    &::before{
        content:"\e93e";
        font-family: 'icomoon';
    }
    &.wizard-icons{
        font-size:20px;
    }
}

.ch-menu{
    &::before{
        content:"\e935";
        font-family: 'icomoon';
    }
    &.wizard-icons{
        font-size:20px;
    }
}
